import React from 'react';
import { Link } from 'react-router-dom';
import './icon-link.css';

const RoadshowLink = () => (
  <Link className="icon-link" to="/roadshows">
    <i className="fas fa-list-ul" />
  </Link>
);

export default RoadshowLink;
