import * as CONSTS from './constants';
import { partialMultipleStateUpdate, partialStateUpdate } from '../../shared/utils/base';

const initialState = {
  pickedDate: new Date().toISOString(),
  idOfRoadshow: null,
  idOfAppointment: null,
  appointments: [],
  roadshows: [],
  ranges: [],
  appointment: {},
};

function appointmentsReducer(state = initialState, action) {
  switch (action.type) {
    case CONSTS.GET_RANGES:
      return { ...state, ranges: action.ranges };
    case CONSTS.CURRENT_DATE:
      return { ...state, pickedDate: action.date };
    case CONSTS.CUR_ID_APPOINTMENT:
      return {
        ...state,
        idOfAppointment: action.id,
      };
    case CONSTS.CUR_ID_ROADSHOW:
      return {
        ...state,
        idOfRoadshow: action.id,
      };
    case CONSTS.GET_ROADSHOWS:
      return {
        ...state,
        roadshows: partialMultipleStateUpdate(state.roadshows, action.roadshows),
      };
    case CONSTS.GET_APPOINTMENTS:
      return {
        ...state,
        appointments: partialMultipleStateUpdate(state.appointments, action.appointments),
      };
    case CONSTS.GET_ROADSHOW:
      return {
        ...state,
        roadshows: partialStateUpdate(state.roadshows, action.roadshow),
      };
    case CONSTS.GET_APPOINTMENT:
      return {
        ...state,
        // appointments: partialStateUpdate(state.appointments, action.appointment),
        appointment: { ...action.appointment },
      };
    case CONSTS.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default appointmentsReducer;
