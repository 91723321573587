import React from 'react';
import propTypes from 'prop-types';
import './style.css';

const Message = ({ text }) => (
  <div className="message">
    <span className="message--title">{text}</span>
  </div>
);

Message.propTypes = {
  text: propTypes.string.isRequired,
};
export default Message;
