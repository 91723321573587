import { changeObjectKeys, keyFromCamelToSnakeCase, timeToUpper } from './base';

export const getIndexById = (array, id) => array.findIndex(item => item.id === id);
export const getAppointmentParameter = (appointment, parameter) =>
  appointment !== null ? appointment[parameter] : null;
export const getAppointmentId = appointment => getAppointmentParameter(appointment, 'id');

function removeAppointment(key) {
  if (key.includes('appointment')) {
    return key.replace('appointment', '');
  }
  return key;
}

export const modifyAppointments = appointments => {
  return appointments.map(appointment =>
    changeObjectKeys(appointment, [keyFromCamelToSnakeCase, timeToUpper, removeAppointment])
  );
};
export const modifyRoadshows = roadshows => {
  return roadshows.map(roadshow =>
    changeObjectKeys(roadshow, [keyFromCamelToSnakeCase, timeToUpper])
  );
};
export const modifyAppointment = appointment =>
  changeObjectKeys(appointment, [keyFromCamelToSnakeCase, timeToUpper, removeAppointment]);
export const modifyRoadshow = roadshow =>
  changeObjectKeys(roadshow, [keyFromCamelToSnakeCase, timeToUpper]);
