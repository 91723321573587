import React from 'react';
import propTypes from 'prop-types';
import './style.css';

const Modal = ({ children }) => (
  <div className="modal">
    <div className="modal-wrapper">{children}</div>
  </div>
);

Modal.propTypes = {
  children: propTypes.node.isRequired,
};

export default Modal;
