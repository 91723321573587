import React from 'react';
import propTypes from 'prop-types';
import { formatToTime } from '../../../../shared/utils/date';
import avatarPlaceholder from '../../../../assets/avatar-placeholder.png';

const ListItem = ({
  appointment: { status, startDateTime, photo, name, companyName },
  current,
}) => {
  const getStatusClassName = () => {
    switch (status) {
      case 'confirmed':
        return 'confirm';
      case 'unconfirmed':
        return 'reject';
      case 'pending':
        return 'pending';
      default:
        return false;
    }
  };

  return (
    <div
      className={`list-item${getStatusClassName() !== false ? ` ${getStatusClassName()}` : ''}${
        current ? ' faded' : ''
      }`}
    >
      <div className="list-item--container">
        <div className="list-item--image">
          <div
            style={{
              backgroundImage: `url(${
                !(photo === undefined || photo == null) ? photo : avatarPlaceholder
              })`,
            }}
          />
        </div>
        <div className="list-item--details">
          <h4 className="name">{name}</h4>
          <h4 className="company-name">{companyName}</h4>
          <h4 className="time">{formatToTime(startDateTime)}</h4>
        </div>
      </div>
    </div>
  );
};

ListItem.propTypes = {
  appointment: propTypes.object,
  current: propTypes.bool,
};

ListItem.defaultProps = {
  appointment: {},
  current: false,
};

export default ListItem;
