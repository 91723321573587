import { apiUrl } from '../configs';
import { getStorageItem, removeStorageItem } from '../shared/utils/localStorage';
import {
  login,
  socialLogin,
  profileSettings,
  askForPermissioToReceiveNotifications,
  logout,
} from './auth';
import {
  getAppointments,
  getRoadshows,
  getAppointment,
  getRoadshowAppointments,
  getActualMeetRanges,
  getActualMeetRangesByLocation,
  modifyAppointment,
  createAppointment,
} from './getData';
import { sendMedia, deleteMedia } from './media';

export const getHeaders = (type = undefined) => {
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  headers.set('Accept', 'application/json');
  if (type === 'media') headers.delete('Content-Type');
  if (getStorageItem('authToken') === 'undefined') removeStorageItem('authToken');
  if (getStorageItem('authToken') !== null)
    headers.set('Authorization', `token ${getStorageItem('authToken')}`);
  return headers;
};
function getKeyError(body) {
  let key;
  // eslint-disable-next-line
  for (const bodyKey in body) {
    if (Object.prototype.hasOwnProperty.call(body, bodyKey)) {
      key = bodyKey;
      break;
    }
  }
  if (typeof body[key] !== 'string') return body[key][0];
  return body[key];
}
export const fetchWrapper = async (url, config) => {
  const response = await fetch(`${apiUrl}${url}`, config);
  const body = await response.json();
  if (response.status >= 400 && response.status < 550) throw Error(getKeyError(body));
  return { response, body };
};

export default {
  login,
  socialLogin,
  profileSettings,
  askForPermissioToReceiveNotifications,
  logout,
  getAppointments,
  getRoadshows,
  getAppointment,
  getRoadshowAppointments,
  getActualMeetRanges,
  getActualMeetRangesByLocation,
  modifyAppointment,
  createAppointment,
  deleteMedia,
  sendMedia,
};
