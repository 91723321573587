import React from 'react';
import propTypes from 'prop-types';
import { setClass } from '../../utils/base';
import './style.css';

const List = ({ list, ListItem, itemProps, className }) => (
  <ul className={`list${setClass(className)}`}>
    {list &&
      list.map(item => (
        <li className="list-item" key={item.id}>
          <ListItem data={item} {...itemProps} />
        </li>
      ))}
  </ul>
);

List.propTypes = {
  list: propTypes.arrayOf(propTypes.object),
  className: propTypes.string,
  ListItem: propTypes.func.isRequired,
  itemProps: propTypes.object,
};
List.defaultProps = {
  list: [{}],
  itemProps: {},
  className: '',
};

export default List;
