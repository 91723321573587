import React, { Component } from 'react';
import propTypes from 'prop-types';
import './icon-link.css';

class AddLink extends Component {
  static propTypes = {
    handleFile: propTypes.func,
    hasFile: propTypes.bool,
    link: propTypes.string,
    type: propTypes.string,
    disabled: propTypes.bool,
  };

  static defaultProps = {
    handleFile: () => {},
    hasFile: false,
    link: '',
    type: '',
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.handleInputFiles = this.handleInputFiles.bind(this);
    this.fileInput = React.createRef();
  }

  componentDidUpdate() {
    if (!this.props.hasFile) this.fileInput.current.value = '';
  }

  handleInputFiles() {
    const { files } = this.fileInput.current;
    if (files.length) this.props.handleFile(files[0]);
  }

  render() {
    const { link, type, disabled } = this.props;
    const getClass = () => {
      let className = 'icon-link file-picker-label';
      if (disabled || link === 'null') className += ' disabled';
      switch (type) {
        case 'large':
          className += ' large';
          return className;
        case 'small':
          className += ' small';
          return className;
        default:
          return className;
      }
    };
    return (
      <label htmlFor="file" className={getClass()} disabled={disabled}>
        <input
          disabled={disabled}
          className="file-picker"
          type="file"
          id="file"
          ref={this.fileInput}
          onChange={this.handleInputFiles}
        />
        <i className="fas fa-plus" />
      </label>
    );
  }
}

export default AddLink;
