import { fetchWrapper, getHeaders } from './index';
import { messaging } from './firebase';
import { setStorageItem, removeStorageItem } from '../shared/utils/localStorage';

export const login = async data => {
  const config = {
    headers: getHeaders(),
    method: 'POST',
    body: JSON.stringify(data),
  };
  const { response, body } = await fetchWrapper('api/auth/', config);
  if (response.status === 200) setStorageItem('authToken', body.token);
};
export const socialLogin = async (social, data) => {
  const config = {
    headers: getHeaders(),
    method: 'POST',
    body: JSON.stringify(data),
  };
  const { response, body } = await fetchWrapper(`api/account/${social}/`, config);
  if (response.status === 200) setStorageItem('authToken', body.key);
};
export const profileSettings = async data => {
  const config = {
    headers: getHeaders(),
    method: 'PATCH',
    body: JSON.stringify(data),
  };
  const { response, body } = await fetchWrapper('api/account/profile/', config);
  return { response, body };
};
export const logout = async () => {
  const config = {
    headers: getHeaders(),
    method: 'POST',
  };
  await fetchWrapper('api/account/dj-rest-auth/logout/', config);
  removeStorageItem('authToken');
};

export const askForPermissioToReceiveNotifications = async () => {
  if ('Notification' in window && 'PushManager' in window) {
    return Notification.requestPermission()
      .then(() => {
        localStorage.setItem('notificationPermission', Notification.permission);
        if (Notification.permission === 'granted' && messaging.isSupported())
          return messaging.getToken({
            vapidKey:
              'BHaScpNCScfclEYUu5eY5Zmx42hcDQZlJYeERyrVE_n_Lj0RkHnIIoh6awPF7m-7Zv4P6nKyVnVaNGDKcRQWFV8',
          });
        return null;
      })
      .then(token => {
        localStorage.setItem('userIdToken', token);
        return token;
      })
      .catch(() => {
        localStorage.setItem('notificationPermission', Notification.permission);
        localStorage.setItem('userIdToken', null);
      });
  }
  return new Promise(resolve => resolve(null));
};
