import { fetchWrapper, getHeaders } from './index';

const getAllData = async reqBody => {
  const config = {
    headers: getHeaders(),
    method: 'GET',
  };
  const { response, body } = await fetchWrapper(`api/${reqBody}/`, config);
  return { response, body };
};
const getDataById = async (reqBody, id) => {
  const config = {
    headers: getHeaders(),
    method: 'GET',
  };
  const { response, body } = await fetchWrapper(`api/${reqBody}/${id}`, config);
  return { response, body };
};
const modifyDataById = async (reqBody, id, data) => {
  const config = {
    headers: getHeaders(),
    method: 'PATCH',
    body: JSON.stringify(data),
  };
  const { response, body } = await fetchWrapper(`api/${reqBody}/${id}/`, config);
  return { response, body };
};
const postData = async (reqBody, data) => {
  const config = {
    headers: getHeaders(),
    method: 'POST',
    body: JSON.stringify(data),
  };
  const { response, body } = await fetchWrapper(`api/${reqBody}/`, config);
  return { response, body };
};
export const getAppointments = async () => {
  return getAllData('appointments');
};
export const getAppointment = async id => {
  return getDataById('appointments', id);
};
export const getRoadshows = async () => {
  return getAllData('roadshows');
};
export const getRoadshowAppointments = async id => {
  return getDataById('appointments', `?id=${id}`);
};
export const getActualMeetRanges = async dates => {
  return getDataById('appointments/free_slots', `?from=${dates.from}&to=${dates.to}`);
};
export const getActualMeetRangesByLocation = async (location, dates) => {
  return getDataById(
    'appointments/free_slots',
    `${location}/?appointment_from=${dates.from}&appointment_to=${dates.to}`
  );
};
export const modifyAppointment = async (id, data) => {
  return modifyDataById('appointments', id, data);
};
export const createAppointment = async data => {
  return postData('appointments', data);
};
