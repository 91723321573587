import React from 'react';
import propTypes from 'prop-types';
import './style.css';

const Preloader = ({ showStatus, children }) => (
  <>
    {showStatus && (
      <div className="preloader">
        <svg className="spinner" viewBox="0 0 100 100">
          <circle className="path" cx="50" cy="50" r="40" fill="none" strokeWidth="5" />
        </svg>
      </div>
    )}
    {children}
  </>
);

Preloader.propTypes = {
  showStatus: propTypes.bool.isRequired,
  children: propTypes.node.isRequired,
};

export default Preloader;
