import { fetchWrapper, getHeaders } from './index';

export const sendMedia = async (reqBody, { file, id }) => {
  const formData = new FormData();
  formData.append(reqBody.slice(0, -1), id);
  formData.append('file', file);
  const config = {
    headers: getHeaders('media'),
    method: 'POST',
    body: formData,
  };
  const { response, body } = await fetchWrapper(`api/${reqBody}/media/upload/`, config);
  return { response, body };
};
export const deleteMedia = async (reqBody, id) => {
  const config = {
    headers: getHeaders(),
    method: 'DELETE',
  };
  const { response, body } = await fetchWrapper(`api/${reqBody}/media/${id}/`, config);
  return { response, body };
};
