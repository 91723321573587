import React from 'react';
import { Provider } from 'react-redux';
import MainLayout from './layouts';
import { setMapStorage } from './shared/utils/localStorage';
import store from './store';

const App = () => {
  setMapStorage('google');
  return (
    <Provider store={store}>
      <MainLayout />
    </Provider>
  );
};

export default App;
