import React from 'react';
import propTypes from 'prop-types';
import './style.css';

const PreloaderComponent = ({ showStatus }) => (
  <div className="preloader-component">
    {showStatus && (
      <svg className="spinner" viewBox="0 0 24 24">
        <circle className="path" cx="12" cy="12" r="10" fill="none" strokeWidth="5" />
      </svg>
    )}
  </div>
);

PreloaderComponent.propTypes = {
  showStatus: propTypes.bool.isRequired,
};

export default PreloaderComponent;
