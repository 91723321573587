/* eslint-disable react/button-has-type */
import React from 'react';
import propTypes from 'prop-types';
import { setClass } from '../../utils/base';
import './style.css';

const Button = ({ handleClick, className, text, type }) => {
  return (
    <button type={type} className={`button${setClass(className)}`} onClick={() => handleClick()}>
      {text}
    </button>
  );
};
Button.propTypes = {
  handleClick: propTypes.func.isRequired,
  text: propTypes.string.isRequired,
  className: propTypes.string,
  type: propTypes.string,
};

Button.defaultProps = {
  className: '',
  type: 'button',
};
export default Button;
