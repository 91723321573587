import React from 'react';
import propTypes from 'prop-types';
import SocialButton from '../../../shared/components/social-button';

const GoogleAuth = ({ handleAuth, handleFailure, token }) => (
  <SocialButton
    appId={token}
    provider="google"
    className="social-login"
    autoCleanUri
    onLoginSuccess={res => handleAuth('google', res)}
    onLoginFailure={res => handleFailure('google', res)}
  >
    <i className="fab fa-google" />
    <span>Login with Google</span>
  </SocialButton>
);

GoogleAuth.propTypes = {
  handleAuth: propTypes.func.isRequired,
  handleFailure: propTypes.func.isRequired,
  token: propTypes.string.isRequired,
};
export default GoogleAuth;
