import { connect } from 'react-redux';
import { fetchRoadshows, currentIdRoadshow } from '../../../store/appointments/actions';
import RoadshowsPage from './component';

const mapStateToProps = ({ appointmentsReducer }) => ({
  roadshows: appointmentsReducer.roadshows,
});
const mapDispatchToProps = dispatch => ({
  fetchRoadshows: () => dispatch(fetchRoadshows()),
  setCurRoadshow: id => dispatch(currentIdRoadshow(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoadshowsPage);
