import React from 'react';
import propTypes from 'prop-types';
import SocialLogin from 'react-social-login';

const Button = ({ children, triggerLogin, ...props }) => (
  <button type="button" onClick={triggerLogin} {...props}>
    {children}
  </button>
);
Button.propTypes = {
  children: propTypes.node.isRequired,
  triggerLogin: propTypes.func.isRequired,
};
export default SocialLogin(Button);
