import { connect } from 'react-redux';
import ListView from './component';
import {
  selectAppointmentsByDates,
  selectRoadshowById,
  selectAppointmentsByRoadshow,
} from '../../../store/appointments/selectors';
import {
  fetchRoadshowAppointments,
  currentIdAppointment,
  getPickedDate,
  deleteMedia,
  sendMedia,
  fetchActualMeetSlotsByLocation,
  createAppointment,
} from '../../../store/appointments/actions';

const mapStateToProps = ({ fetchReducer, appointmentsReducer }) => ({
  appointments: selectAppointmentsByDates(appointmentsReducer),
  allAppointments: selectAppointmentsByRoadshow(appointmentsReducer),
  roadshow: selectRoadshowById(appointmentsReducer),
  roadshowId: appointmentsReducer.idOfRoadshow,
  pickedDate: appointmentsReducer.pickedDate,
  isFetching: fetchReducer.isFetching,
  responseFailed: fetchReducer.responseFailed,
  errorMessage: fetchReducer.errorMessage,
  ranges: appointmentsReducer.ranges,
});
const mapDispatchToProps = dispatch => ({
  handlePickedDate: date => dispatch(getPickedDate(date)),
  fetchAppointments: (id, date) => dispatch(fetchRoadshowAppointments(id, date)),
  setCurAppointment: id => dispatch(currentIdAppointment(id)),
  deleteMedia: id => dispatch(deleteMedia('roadshows', id)),
  sendMedia: file => dispatch(sendMedia('roadshows', file)),
  fetchActualMeetSlotsByLocation: (location, dates) =>
    dispatch(fetchActualMeetSlotsByLocation(location, dates)),
  createAppointment: data => dispatch(createAppointment(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListView);
