import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import Login from '../features/auth/layouts';
import Settings from '../features/settings/index';
import RoadshowPage from '../features/roadshows/layouts';
import ListView from '../features/list-view/layouts';
import Appointment from '../features/appointment/layouts';

const MainLayout = () => {
  const redirectFromLogin = (Component, props) => {
    return localStorage.getItem('authToken') !== null ? (
      <Redirect to="/roadshow" />
    ) : (
      <Component {...props} />
    );
  };
  const isAuthentificated = (Component, props) => {
    return localStorage.getItem('authToken') !== null ? (
      <Component {...props} />
    ) : (
      <Redirect to="/" />
    );
  };
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={props => redirectFromLogin(Login, props)} />
        <Route exact path="/linkedin" component={LinkedInPopUp} />
        <Route path="/settings" render={props => isAuthentificated(Settings, props)} />
        <Route path="/roadshows" render={props => isAuthentificated(RoadshowPage, props)} />
        <Route
          path="/roadshow/:id"
          alias="roadshow"
          render={props => isAuthentificated(ListView, props)}
        />
        <Route path="/appointment/:id" render={props => isAuthentificated(Appointment, props)} />
        <Route render={props => isAuthentificated(ListView, props)} />
      </Switch>
    </BrowserRouter>
  );
};

export default MainLayout;
