import React from 'react';
import PropTypes from 'prop-types';
import { Map, Marker } from 'google-maps-react';
import { GOOGLE_API_KEY } from '../../../configs/index';

function MapContainer(props) {
  return (
    <div style={props.style}>
      <Map
        centerAroundCurrentLocation
        style={props.style}
        initialCenter={props.marker}
        center={props.marker}
        google={window.google}
        onClick={props.onClick}
      >
        <Marker
          onClick={props.onMarkerClick}
          name={props.formatedAddress}
          position={props.marker}
        />
      </Map>
    </div>
  );
}
export const getPlaceByCoords = async ({ lat, lng }, LANGUAGE = 'en') => {
  const GOOGLE_API = 'https://maps.google.com/maps/api/geocode/json';
  const latLng = `${lat},${lng}`;
  const url = `${GOOGLE_API}?latlng=${encodeURIComponent(
    latLng
  )}&key=${GOOGLE_API_KEY}&language=${LANGUAGE}`;
  const response = await fetch(url);
  const { results } = await response.json();
  return results[0] || { formatted_address: 'Address not found' };
};

// export default GoogleApiWrapper({
//   apiKey: GOOGLE_API_KEY,
// })(MapContainer);
// GoogleApiWrapper isn't required cause it connects throw public/index.html
// for purposes of react-places-autocomplete
export default MapContainer;

MapContainer.propTypes = {
  style: PropTypes.object,
  marker: PropTypes.object,
  formatedAddress: PropTypes.string,
  onClick: PropTypes.func,
  onMarkerClick: PropTypes.func,
};

MapContainer.defaultProps = {
  style: {},
  marker: {},
  formatedAddress: 'Address',
  onClick: () => null,
  onMarkerClick: () => null,
};
