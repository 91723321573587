/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import propTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Notification extends Component {
  static propTypes = {
    show: propTypes.bool,
    type: propTypes.string,
    message: propTypes.any.isRequired,
  };

  static defaultProps = {
    show: false,
    type: '',
  };

  componentDidMount() {
    const { type, message, show } = this.props;
    const messageToShow = typeof message === 'object' ? JSON.stringify(message) : message;
    if (show) {
      switch (type) {
        case 'error':
          toast.error(messageToShow !== undefined ? messageToShow : 'Error');
          break;
        case 'warning':
          toast.warn(messageToShow);
          break;
        default:
          toast.success(messageToShow);
          break;
      }
    }
  }

  render() {
    return <ToastContainer autoClose={3000} />;
  }
}

export default Notification;
