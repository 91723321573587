import Firebase from 'firebase/app';
import 'firebase/messaging';

export default Firebase.initializeApp({
  apiKey: 'AIzaSyD6wARuqQfX8Q3zarzpgsGGXBvRYFLaop0',
  authDomain: 'test-3ad68.firebaseapp.com',
  storageBucket: 'test-3ad68.appspot.com',
  messagingSenderId: '121302191606',
  projectId: 'test-3ad68',
  appId: '1:121302191606:web:5d7522aa2f86de7a59cbd7',
});

export const messaging = Firebase.messaging.isSupported()
  ? Firebase.messaging()
  : {
      onMessage: () => null,
      isSupported: () => Firebase.messaging.isSupported(),
    };

messaging.onMessage(payload => {
  const {
    notification: { title, ...notification },
    data,
  } = payload;

  return new Notification(title, {
    icon: '/destination-icon.png',
    badge: '/destination-icon.png',
    vibrate: [500, 110, 500, 110, 450, 110, 200, 110, 170, 40],
    ...notification,
    data,
  });
});
