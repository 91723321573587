import { getIndexById } from './appointment';

export function setClass(className) {
  return className ? `${` ${className}`}` : '';
}
export function setImage(photo, placeholder) {
  return !(photo === undefined || photo === null) ? photo : placeholder;
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function stringToUpper(key, string) {
  if (key.includes(string, 1)) {
    return key.replace(string, capitalize(string));
  }
  return key;
}

export function timeToUpper(key) {
  return stringToUpper(key, 'time');
}

export function keyFromCamelToSnakeCase(key) {
  if (key.includes('_')) {
    return key
      .split('_')
      .reduce((acc, val, idx) => (idx === 0 ? val : acc + capitalize(val)), undefined);
  }
  return key;
}

export function changeObjectKeys(obj, keyHandlers) {
  if (obj !== {}) {
    const newObject = {};
    Object.keys(obj).forEach(key => {
      const newName = keyHandlers.reduce((val, handler) => handler(val), key);
      if (typeof obj[key] !== 'object') {
        newObject[newName] = obj[key];
      } else if (obj[key] instanceof Array) {
        newObject[newName] = obj[key];
      } else if (obj[key] instanceof Object) {
        newObject[newName] = changeObjectKeys(obj[key], keyHandlers);
      }
    });
    return newObject;
  }
  return obj;
}

export function partialStateUpdate(curState, newData) {
  if (!curState.length) return [newData];
  return [
    ...curState.slice(0, getIndexById(curState, newData.id)),
    newData,
    ...curState.slice(getIndexById(curState, newData.id) + 1),
  ];
}

export function partialMultipleStateUpdate(curState, newData) {
  if (!curState.length) return [...newData];
  return [...curState.filter(item => !newData.some(newItem => newItem.id === item.id)), ...newData];
}
