import { createSelector } from 'reselect';
import { getIndexById, getAppointmentId as appointmentId } from '../../shared/utils/appointment';
import { sameDay } from '../../shared/utils/date';

const getAppointments = reducer => reducer.appointments;
const getRoadshows = reducer => reducer.roadshows;
const getAppointmentId = reducer => reducer.idOfAppointment;
const getRoadshowId = reducer => reducer.idOfRoadshow;
const getPickedDate = reducer => reducer.pickedDate;

export const selectAppointments = createSelector(
  [getAppointments, getRoadshowId],
  (appointments, id) => appointments.filter(({ roadshow }) => roadshow.id === id)
);
export const selectAppointmentById = createSelector(
  [getAppointments, getAppointmentId],
  (appointments, id) => appointments.find(appointment => appointment.id === id)
);
export const selectRoadshowById = createSelector([getRoadshows, getRoadshowId], (roadshows, id) =>
  roadshows.find(roadshow => roadshow.id === id)
);
export const selectAppointmentsByDates = createSelector(
  [getAppointments, getRoadshowId, getPickedDate],
  (appointments, id, pickedDate) =>
    appointments
      .filter(({ roadshow }) => roadshow.id === id)
      .filter(appointment => sameDay(appointment.startDateTime, pickedDate))
);
export const selectNextAppointmentById = createSelector(
  [getAppointments, getAppointmentId],
  (appointments, id) => appointmentId(appointments[getIndexById(appointments, id) + 1] || null)
);
export const selectPrevAppointmentById = createSelector(
  [getAppointments, getAppointmentId],
  (appointments, id) => appointmentId(appointments[getIndexById(appointments, id) - 1] || null)
);
export const selectAppointmentsByRoadshow = createSelector(
  [getAppointments, getRoadshowId],
  (appointments, id) => appointments.filter(({ roadshow }) => roadshow.id === id)
);
