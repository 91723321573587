import React, { Component } from 'react';
import propTypes from 'prop-types';
import Login from '../components/login-form';
import GoogleAuth from '../components/google-auth';
import LinkedinAuth from '../components/linkedin-auth';
import Notification from '../../../shared/components/notification';
import { googleToken, linkedinToken } from '../../../configs';
import { getStorageItem } from '../../../shared/utils/localStorage';
import { utcOffset } from '../../../shared/utils/date';
import API from '../../../api';
import './style.css';

class LoginPage extends Component {
  static propTypes = {
    login: propTypes.func.isRequired,
    socialLogin: propTypes.func.isRequired,
    onResponseFail: propTypes.func.isRequired,
    errorMessage: propTypes.string.isRequired,
    responseFailed: propTypes.bool.isRequired,
    isFetching: propTypes.bool.isRequired,
    history: propTypes.shape({
      push: propTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      notificationPermission: getStorageItem('notificationPermission'),
      userIdToken: getStorageItem('userIdToken'),
      authToken: getStorageItem('authToken'),
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleSocialAuth = this.handleSocialAuth.bind(this);
    this.handleSocialFail = this.handleSocialFail.bind(this);
  }

  componentDidMount() {
    API.askForPermissioToReceiveNotifications().then(token =>
      this.setState({ userIdToken: token })
    );
  }

  handleFormSubmit(e, authObj) {
    const { notificationPermission, userIdToken, authToken } = this.state;
    e.preventDefault();
    const { login, history } = this.props;
    login(authObj).then(() => {
      if (notificationPermission === 'granted' && authToken)
        API.profileSettings({ device_id: userIdToken, timezone: utcOffset() });
      history.push('/roadshow');
    });
  }

  handleSocialAuth(social, response) {
    const { history, socialLogin } = this.props;
    const { notificationPermission, userIdToken, authToken } = this.state;
    let reqBody;
    // eslint-disable-next-line no-underscore-dangle
    if (social === 'google') reqBody = { access_token: response._token.accessToken };
    if (social === 'linkedin') reqBody = { code: response.code };
    socialLogin(social, reqBody).then(() => {
      if (notificationPermission === 'granted' && authToken)
        API.profileSettings({ device_id: userIdToken, timezone: utcOffset() });
      history.push('/roadshow');
    });
  }

  handleSocialFail(provider, response) {
    const { onResponseFail } = this.props;
    onResponseFail({ message: response.message });
  }

  render() {
    const { isFetching, responseFailed, errorMessage } = this.props;
    return (
      <main className="login-page">
        <img
          className="login-page--logo"
          src={`${process.env.PUBLIC_URL}/destination-icon.png`}
          alt="Logo"
        />
        <h1>Rozklad.app</h1>
        <Login isFetching={isFetching} handleSubmit={this.handleFormSubmit} />
        <div className="social-container">
          <GoogleAuth
            token={googleToken}
            handleAuth={this.handleSocialAuth}
            handleFailure={this.handleSocialFail}
          />
          <LinkedinAuth
            token={linkedinToken}
            handleAuth={this.handleSocialAuth}
            handleFailure={this.handleSocialFail}
          />
        </div>
        <Notification type="error" show={responseFailed} message={errorMessage} delay={3000} />
      </main>
    );
  }
}

export default LoginPage;
