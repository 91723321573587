import React from 'react';
import propTypes from 'prop-types';
import File from '../../../../shared/components/user-file';
// import './style.css';

const RoadshowFiles = ({ files, handleFileDelete }) => {
  return (
    <div className="roadshow-media">
      <div className="attachments">
        {files.map(file => (
          <File
            className="file-item"
            file={file}
            key={file.id}
            handleFileDelete={handleFileDelete}
          />
        ))}
      </div>
    </div>
  );
};
RoadshowFiles.propTypes = {
  files: propTypes.array.isRequired,
  handleFileDelete: propTypes.func,
};
RoadshowFiles.defaultProps = {
  handleFileDelete: () => {},
};
export default RoadshowFiles;
