import React, { Component } from 'react';
import propTypes from 'prop-types';
import Drag from '../drag';
import ExtensionIcon, { imageRegex } from '../extension-icon';
import './style.css';

class File extends Component {
  static propTypes = {
    className: propTypes.string,
    file: propTypes.object,
    isUpload: propTypes.bool,
    handleFileUpload: propTypes.func,
    handleFileDelete: propTypes.func,
  };

  static defaultProps = {
    className: '',
    file: {},
    isUpload: false,
    handleFileUpload: () => {},
    handleFileDelete: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      refOffset: 0,
    };
    this.dragRef = this.dragRef.bind(this);
  }

  dragRef(node) {
    this.setState({ refOffset: node.offsetHeight });
  }

  render() {
    const { file, handleFileDelete, handleFileUpload, isUpload, className } = this.props;
    const { refOffset } = this.state;
    const getFileName = () => (isUpload ? file.name : file.filename);
    const getFileType = () => (isUpload ? file.type : file.media_type);
    const getFileLink = () => (isUpload ? file.urlImage : file.file);
    const getFileId = () => !isUpload && file.id;
    const handleClickDeleteBtn = () => {
      const args = !isUpload && { filename: getFileName(), id: getFileId() };
      handleFileDelete(args);
    };
    return (
      <div className={`file${className && ` ${className}`}`}>
        {isUpload ? (
          <Drag
            className="file--container"
            offset={refOffset + 10}
            elementRef={this.dragRef}
            left
            right
          >
            <div className="file--wrapper">
              {imageRegex.test(getFileType()) ? (
                <div
                  style={{
                    backgroundImage: `url(${getFileLink()})`,
                  }}
                  className="file--view"
                />
              ) : (
                <ExtensionIcon ext={getFileType()} className="file--view" />
              )}
              <p className="file--name">{getFileName()}</p>
            </div>
          </Drag>
        ) : (
          <Drag className="file--container" offset={refOffset + 10} elementRef={this.dragRef} right>
            <a
              href={getFileLink()}
              className="file--wrapper"
              target={imageRegex.test(getFileType()) ? '_self' : '_blank'}
              rel="noreferrer"
              onKeyPress={e => e.stopPropagation()}
              onClick={e => e.stopPropagation()}
            >
              {imageRegex.test(getFileType()) ? (
                <div
                  style={{
                    backgroundImage: `url(${file.thumbnail_image || file.file})`,
                  }}
                  className="file--view"
                />
              ) : (
                <ExtensionIcon ext={getFileType()} className="file--view" />
              )}
              <p className="file--name">{getFileName()}</p>
            </a>
          </Drag>
        )}
        {isUpload && (
          <div
            className="file--controls upload"
            role="button"
            tabIndex={0}
            onKeyPress={() => handleFileUpload()}
            onClick={() => handleFileUpload()}
          >
            <i className="fas fa-file-upload" />
          </div>
        )}
        <div
          className="file--controls delete"
          role="button"
          tabIndex={0}
          onKeyPress={() => handleClickDeleteBtn()}
          onClick={() => handleClickDeleteBtn()}
        >
          <i className="far fa-trash-alt" />
        </div>
      </div>
    );
  }
}
export default File;
