import React from 'react';
import propTypes from 'prop-types';
import { formatToISO, formatToDay, dayIsBetween, sameDay, addDay } from '../../utils/date';
import './style.css';

const DatePick = ({ pickedDate, startDate, endDate, handleDate }) => {
  const renderDays = () => {
    let days = [];
    let dayIterator = startDate;
    while (dayIsBetween(dayIterator, startDate, endDate)) {
      const current = dayIterator;
      days = [
        ...days,
        <div
          role="link"
          tabIndex="0"
          key={formatToISO(current)}
          onClick={() => handleDate(formatToISO(current))}
          onKeyPress={() => handleDate(formatToISO(current))}
          className={`day${sameDay(dayIterator, pickedDate) ? ' current' : ''}`}
        >
          {formatToDay(current)}
        </div>,
      ];
      dayIterator = addDay(dayIterator);
    }
    return days;
  };

  return (
    <div className="date-pick">
      <div className="date-pick--container">{renderDays()}</div>
    </div>
  );
};
DatePick.propTypes = {
  startDate: propTypes.string,
  endDate: propTypes.string,
  pickedDate: propTypes.string,
  handleDate: propTypes.func.isRequired,
};

DatePick.defaultProps = {
  startDate: '',
  endDate: '',
  pickedDate: '',
};

export default DatePick;
