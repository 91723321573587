import React, { Component } from 'react';
import propTypes from 'prop-types';
import Button from '../../shared/components/button/button';
import API from '../../api/index';
import { setMapStorage, getMapStorage } from '../../shared/utils/localStorage';
import { codeVersion } from '../../configs';
import './style.css';

class Settings extends Component {
  static propTypes = {
    history: propTypes.shape({
      push: propTypes.func.isRequired,
    }).isRequired,
    clearStoreState: propTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      // geolocation: {
      //   show: false,
      //   useGeo: false,
      // },
      selectedMapOption: getMapStorage(),
      mapOptions: [
        {
          label: 'Google',
          value: 'google',
        },
        {
          label: 'Apple',
          value: 'apple',
        },
        {
          label: 'Citymapper',
          value: 'citymapper',
        },
      ],
    };

    this.handleLogoutButton = this.handleLogoutButton.bind(this);
    // this.handleGeoOptions = this.handleGeoOptions.bind(this);
    this.handleMapChange = this.handleMapChange.bind(this);
  }

  // componentDidMount() {
  //   if (navigator.geolocation) {
  //     this.setState({ geolocation: { show: true } });
  //   }
  // }

  // handleGeoOptions(e) {
  //   this.setState({ geolocation: { useGeo: e.target.value } });
  // }

  handleMapChange(e) {
    const { selectedMapOption } = this.state;
    this.setState({
      selectedMapOption: e.target.value,
    });
    if (selectedMapOption !== '') setMapStorage(e.target.value);
  }

  async handleLogoutButton() {
    await API.logout();
    this.props.clearStoreState();
    this.props.history.push('/');
  }

  render() {
    const { selectedMapOption, mapOptions } = this.state;
    const { history } = this.props;
    // const {
    //   geolocation: { useGeo, show },
    // } = this.state;
    return (
      <main className="settings">
        <div className="field-area">
          <h5 className="field-area--title">Choose map provider:</h5>
          <div className="field-area--options">
            {mapOptions.map(({ value, label }) => (
              <label
                key={value}
                htmlFor={value}
                className={`field-area--label ${selectedMapOption === value ? 'checked' : ''}`}
              >
                <input
                  className="radio-picker"
                  type="radio"
                  label={label}
                  id={value}
                  value={value}
                  checked={selectedMapOption === value}
                  onChange={this.handleMapChange}
                />
                {label}
              </label>
            ))}
          </div>
        </div>
        {/* <div className="field-area">
          <h5 className="field-area--title">Use geo for get map link:</h5>
          <div className="field-area--options">{useGeo}</div>
          <div className="field-area--options">{show}</div>
        </div> */}
        <div className="field-area">
          <h5 className="field-area--title">
            Current code version:
            {` ${codeVersion}`}
          </h5>
        </div>
        <div className="page-buttons">
          <Button handleClick={() => history.push('/roadshows')} text="Go to a roadshows" />
          <Button handleClick={this.handleLogoutButton} className="logout" text="Logout" />
        </div>
      </main>
    );
  }
}
export default Settings;
