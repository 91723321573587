import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { formatToDateRange } from '../../../../shared/utils/date';
import cityPlaceholder from '../../../../assets/city-placeholder.jpg';
import './style.css';

const RoadshowItem = ({
  className,
  handleItemClick,
  data: { id, photo, name, startDateTime, endDateTime },
  ...props
}) => (
  <Link
    className={`roadshow-item ${className ? `${` ${className}`}` : ''}`}
    to={`${id ? `/roadshow/${id}` : '/roadshow'}`}
    onClick={() => handleItemClick(id)}
    {...props}
  >
    <div
      className="roadshow-item--image"
      style={{
        backgroundImage: `url(${
          !(photo === undefined || photo === null) ? photo : cityPlaceholder
        })`,
      }}
    />
    <div className="roadshow-item--title-placeholder">
      <h3 className="roadshow-item--title">{name}</h3>
      <h3 className="roadshow-item--title">{formatToDateRange(startDateTime, endDateTime)}</h3>
    </div>
  </Link>
);

RoadshowItem.propTypes = {
  className: propTypes.string,
  data: propTypes.object,
  handleItemClick: propTypes.func,
};
RoadshowItem.defaultProps = {
  className: '',
  data: {},
  handleItemClick: () => {},
};
export default RoadshowItem;
