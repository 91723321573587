import React, { Component } from 'react';
import propTypes from 'prop-types';
import deepEqual from 'deep-equal';
// import { CSSTransition } from 'react-transition-group';
// import Popup from '../../../shared/components/popup';
import File from '../../../shared/components/user-file';
import Header from '../../../shared/components/header';
import Preloader from '../../../shared/components/preloader';
import Swiper from '../../../shared/components/swiper';
import List from '../components/list';
import DatePick from '../../../shared/components/date-pick';
import RoadshowFiles from '../components/media';
import RoadshowItem from '../../roadshows/components/item';
import Navbar from '../../../shared/components/navbar';
import {
  dayIsBetween,
  subDayFormat,
  addDayFormat,
  afterDate,
  beforeDate,
  sameDay,
} from '../../../shared/utils/date';
import AppointmentEditAddress from '../components/edit-address';
import AppointmentEditTime from '../components/edit-time';
import AppointmentEditPerson from '../components/edit-person';
import Message from '../../../shared/components/message';
import './style.css';
import Notification from '../../../shared/components/notification';

class ListView extends Component {
  static propTypes = {
    roadshowId: propTypes.string,
    appointments: propTypes.arrayOf(propTypes.object),
    roadshow: propTypes.object,
    pickedDate: propTypes.string,
    fetchAppointments: propTypes.func.isRequired,
    handlePickedDate: propTypes.func.isRequired,
    // sendMedia: propTypes.func.isRequired,
    // deleteMedia: propTypes.func.isRequired,
    match: propTypes.object.isRequired,
    isFetching: propTypes.bool.isRequired,
    // responseFailed: propTypes.bool,
    history: propTypes.shape({
      push: propTypes.func.isRequired,
    }).isRequired,
    ranges: propTypes.arrayOf(propTypes.object),
    fetchActualMeetSlotsByLocation: propTypes.func.isRequired,
    createAppointment: propTypes.func.isRequired,
    allAppointments: propTypes.array,
  };

  static defaultProps = {
    appointments: [{}],
    roadshow: {},
    roadshowId: '',
    pickedDate: '',
    // responseFailed: false,
    ranges: [{}],
    allAppointments: [],
  };

  static getDerivedStateFromProps(nextProps) {
    return { hasAppointments: nextProps.roadshow.id === nextProps.roadshowId };
  }

  constructor(props) {
    super(props);
    this.state = {
      // hasAppointments: false,
      showFiles: false,
      file: {
        hasFile: false,
        file: {},
        localFile: {},
      },
      showAllDays: false,
      // popup: {
      //   title: '',
      //   text: '',
      //   show: false,
      // },
      isEditingAddress: false,
      isEditingTime: false,
      isSelectingDateTime: false,
      isEditingPerson: false,
      selectedAddress: '',
      selectedPosition: {},
      selectedTimeRange: {},
      selectedDateTime: '',
      googleApiError: '',
    };
    // this.handleFile = this.handleFile.bind(this);
    // this.handleFileUpload = this.handleFileUpload.bind(this);
    // this.handleLocalDelete = this.handleLocalDelete.bind(this);
    // this.handleFileDelete = this.handleFileDelete.bind(this);
    // this.handleFilesState = this.handleFilesState.bind(this);
    // this.handlePopupState = this.handlePopupState.bind(this);
    this.handleRoute = this.handleRoute.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleRightSwipe = this.handleRightSwipe.bind(this);
    this.handleLeftSwipe = this.handleLeftSwipe.bind(this);
    this.handleVerticalSwipe = this.handleVerticalSwipe.bind(this);
    this.handleEditData = this.handleEditData.bind(this);
    this.handleEditTime = this.handleEditTime.bind(this);
    this.handleEditPerson = this.handleEditPerson.bind(this);
    this.handleEditAppointmentAddress = this.handleEditAppointmentAddress.bind(this);
    this.handleEditAppointmentDatetime = this.handleEditAppointmentDatetime.bind(this);
    this.handleAddAppointment = this.handleAddAppointment.bind(this);
    this.handleShowAllDays = this.handleShowAllDays.bind(this);
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      roadshowId,
      fetchAppointments,
      pickedDate,
    } = this.props;
    fetchAppointments(id || roadshowId, pickedDate);
    console.log(this.props.appointments);
    // this.setState({ hasAppointments: roadshow.id === roadshowId });
  }

  componentDidUpdate(prevProps) {
    if (!deepEqual(this.props.roadshow, prevProps.roadshow)) {
      const { roadshow, pickedDate, handlePickedDate } = this.props;
      const { startDateTime, endDateTime } = roadshow;
      if (afterDate(pickedDate, endDateTime)) handlePickedDate(roadshow.startDateTime);
      if (beforeDate(pickedDate, startDateTime)) handlePickedDate(roadshow.startDateTime);
    }
  }

  // handleFilesState() {
  //   this.setState(({ showFiles }) => ({ showFiles: !showFiles }));
  // }

  // handleFileUpload() {
  //   const {
  //     file: { file, id },
  //   } = this.state;
  //   const {
  //     roadshowId,
  //     pickedDate,
  //     sendMedia,
  //     fetchAppointments,
  //     match: { params },
  //   } = this.props;
  //   sendMedia({ file, id }).then(() => {
  //     this.setState({ file: { file: {}, localFile: {}, hasFile: false } });
  //     fetchAppointments(params.id || roadshowId, pickedDate);
  //   });
  // }

  // handleFileDelete(file) {
  //   this.setState({
  //     popup: {
  //       show: true,
  //       title: 'Confirm delete!',
  //       text: `Are you sure about deleting file: ${file.filename}?`,
  //       file,
  //     },
  //   });
  // }

  // handlePopupState(res) {
  //   const { deleteMedia } = this.props;
  //   const {
  //     popup: { file },
  //   } = this.state;
  //   this.setState({ popup: { show: false, title: '', text: '', file: undefined } });
  //   if (res === true)
  //     deleteMedia(file.id).then(() => {
  //       const {
  //         roadshow: { media },
  //         responseFailed,
  //         pickedDate,
  //         fetchAppointments,
  //         match: { params },
  //       } = this.props;
  //       if (!responseFailed) {
  //         this.setState({
  //           file: { file: {}, localFile: {}, hasFile: false },
  //           showFiles: !!media.length,
  //         });
  //         fetchAppointments(params.id, pickedDate);
  //       }
  //     });
  // }

  // handleLocalDelete() {
  //   this.setState({ file: { file: {}, localFile: {}, hasFile: false } });
  // }

  // handleFile(file) {
  //   const {
  //     match: { params },
  //   } = this.props;
  //   this.setState({ showFiles: false });
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onloadend = () => {
  //     this.setState({
  //       file: {
  //         file,
  //         id: params.id,
  //         localFile: { name: file.name, type: file.type, urlImage: reader.result },
  //         hasFile: true,
  //       },
  //     });
  //   };
  // }

  handleShowAllDays() {
    this.setState(prevState => ({ showAllDays: !prevState.showAllDays }));
  }

  handleRoute(routeId) {
    this.props.history.push(`/appointment/${routeId}`);
  }

  handleDate(date) {
    this.props.handlePickedDate(date);
  }

  handleLeftSwipe() {
    const {
      handlePickedDate,
      pickedDate,
      roadshow: { startDateTime, endDateTime },
    } = this.props;
    if (afterDate(pickedDate, endDateTime)) handlePickedDate(endDateTime);
    if (beforeDate(pickedDate, startDateTime)) handlePickedDate(startDateTime);
    if (dayIsBetween(pickedDate, startDateTime, endDateTime)) {
      if (sameDay(pickedDate, endDateTime)) handlePickedDate(endDateTime);
      else handlePickedDate(addDayFormat(pickedDate));
    }
  }

  handleRightSwipe() {
    const {
      handlePickedDate,
      pickedDate,
      roadshow: { startDateTime, endDateTime },
    } = this.props;
    if (afterDate(pickedDate, endDateTime)) handlePickedDate(endDateTime);
    if (beforeDate(pickedDate, startDateTime)) handlePickedDate(startDateTime);
    if (dayIsBetween(pickedDate, startDateTime, endDateTime)) {
      if (sameDay(pickedDate, startDateTime)) handlePickedDate(startDateTime);
      else handlePickedDate(subDayFormat(pickedDate));
    }
  }

  handleVerticalSwipe(event) {
    const { roadshowId, fetchAppointments, pickedDate } = this.props;
    if (event.absY >= 200) fetchAppointments(roadshowId, pickedDate);
  }

  handleEditData() {
    this.setState(prevState => ({ isEditingAddress: !prevState.isEditingAddress }));
  }

  handleEditTime(obj) {
    this.setState(prevState => ({
      isEditingTime: !prevState.isEditingTime,
      selectedTimeRange: obj ? { ...obj } : prevState.selectedTimeRange,
    }));
  }

  handleEditPerson() {
    this.setState(prevState => ({ isEditingPerson: !prevState.isEditingPerson }));
  }

  handleEditAppointmentAddress(location, position) {
    const { roadshow, fetchActualMeetSlotsByLocation } = this.props;
    fetchActualMeetSlotsByLocation(location, {
      from: roadshow.startDateTime,
      to: roadshow.endDateTime,
    }).then(
      () =>
        this.setState(prevState => ({
          isEditingAddress: !prevState.isEditingAddress,
          isSelectingDateTime: true,
          selectedAddress: location,
          selectedPosition: {
            latitude: position.lat,
            longitude: position.lng,
          },
        })),
      () => {
        this.setState({ googleApiError: 'This time period is now locked for editing.' });
        setTimeout(() => this.setState({ googleApiError: '' }), 3000);
      }
    );
  }

  handleEditAppointmentDatetime({ startDateTime }) {
    this.setState(prevState => ({
      isSelectingDateTime: !prevState.isSelectingDateTime,
      isEditingTime: !prevState.isEditingTime,
      isEditingPerson: true,
      selectedDateTime: startDateTime,
    }));
  }

  handleAddAppointment(obj) {
    const {
      roadshowId,
      handlePickedDate,
      fetchAppointments,
      pickedDate,
      createAppointment,
    } = this.props;
    const { selectedAddress, selectedPosition, selectedDateTime } = this.state;
    const data = {
      roadshow: roadshowId,
      appointmentplace: {
        address: selectedAddress || undefined,
        position: selectedPosition,
      },
      start_datetime: selectedDateTime || undefined,
      appointmentperson: {
        name: obj.name,
        position: obj.position,
        company_name: obj.company_name,
        company_linkedin_url: obj.company_linkedin_url,
        description: obj.description,
      },
    };
    createAppointment(data).then(() => {
      this.setState({
        isEditingAddress: false,
        isEditingTime: false,
        isSelectingDateTime: false,
        isEditingPerson: false,
      });
      handlePickedDate(selectedDateTime);
      fetchAppointments(roadshowId, pickedDate);
    });
  }

  render() {
    const {
      roadshow,
      roadshowId,
      pickedDate,
      isFetching,
      appointments,
      ranges,
      allAppointments,
    } = this.props;
    const { startDateTime, endDateTime } = roadshow;
    const {
      // hasAppointments,
      showFiles,
      // popup,
      file: { localFile, hasFile },
      isEditingAddress,
      isEditingTime,
      isSelectingDateTime,
      selectedTimeRange,
      isEditingPerson,
      googleApiError,
      showAllDays,
    } = this.state;
    return (
      <>
        <Preloader showStatus={isFetching}>
          <Header className="roadshow-header">
            <Navbar />
            <RoadshowItem data={roadshow} className="roadshow" />
            <div className="switch-list">
              <button
                type="button"
                className="switch-list-appointments"
                onClick={() => this.handleShowAllDays()}
              >
                <span className={`${showAllDays ? 'hide' : 'show'}`}>
                  <i className="fas fa-list" />
                </span>
                <span className={`${showAllDays ? 'show' : 'hide'}`}>
                  <i className="fas fa-calendar-alt" />
                </span>
              </button>
            </div>
          </Header>
          <main className="list-view">
            {roadshowId ? (
              <>
                {showAllDays ? (
                  <>
                    <DatePick
                      startDate={startDateTime}
                      endDate={endDateTime}
                      pickedDate={pickedDate}
                      handleDate={this.handleDate}
                    />
                    <Swiper
                      handlerLeftSwipes={this.handleLeftSwipe}
                      handlerRightSwipes={this.handleRightSwipe}
                      handlerVerticalSwipes={this.handleVerticalSwipe}
                      className="swipe-list"
                    >
                      <List
                        appointments={appointments}
                        handleRoute={this.handleRoute}
                        handleEditData={this.handleEditData}
                        handleEditTime={this.handleEditTime}
                        freeSpots={ranges}
                        pickedDate={pickedDate}
                        isSelectingDateTime={isSelectingDateTime}
                      />
                    </Swiper>
                  </>
                ) : (
                  <>
                    <List
                      appointments={allAppointments}
                      handleRoute={this.handleRoute}
                      handleEditData={this.handleEditData}
                      handleEditTime={this.handleEditTime}
                      freeSpots={ranges}
                      pickedDate={pickedDate}
                      isSelectingDateTime={isSelectingDateTime}
                    />
                  </>
                )}
              </>
            ) : (
              <Message text="No roadshow" />
            )}
            {roadshow.media !== undefined && !!roadshow.media.length && showFiles && (
              <div className="files-container">
                <RoadshowFiles files={roadshow.media} handleFileDelete={this.handleFileDelete} />
              </div>
            )}
          </main>
          {hasFile && (
            <div className="new-file-upload">
              <File
                file={localFile}
                isUpload={hasFile}
                handleFileUpload={this.handleFileUpload}
                handleFileDelete={this.handleLocalDelete}
              />
            </div>
          )}
          {/* <LinksGroup
            handleFile={this.handleFile}
            disableAddFiles={!hasAppointments}
            disableShowFiles={roadshow.media === undefined || !roadshow.media.length}
            handleClickPopup={this.handleFilesState}
          /> */}
          {/* <CSSTransition in={popup.show} timeout={200} classNames="popup">
            <Popup
              message={{ title: popup.title, text: popup.text }}
              show={popup.show}
              handleCallback={this.handlePopupState}
            />
          </CSSTransition> */}
          {isEditingAddress && (
            <AppointmentEditAddress
              isFetching={isFetching}
              handleClose={this.handleEditData}
              handleSubmit={this.handleEditAppointmentAddress}
            />
          )}
          {isEditingTime && (
            <AppointmentEditTime
              isFetching={isFetching}
              range={selectedTimeRange}
              handleClose={this.handleEditTime}
              handleSubmit={this.handleEditAppointmentDatetime}
            />
          )}
          {isEditingPerson && (
            <AppointmentEditPerson
              isFetching={isFetching}
              handleClose={this.handleEditPerson}
              handleSubmit={this.handleAddAppointment}
            />
          )}
          <Notification
            type="error"
            show={googleApiError.length > 0}
            message={googleApiError}
            delay={3000}
          />
        </Preloader>
      </>
    );
  }
}

export default ListView;
