import React from 'react';
import propTypes from 'prop-types';
import { Swipeable } from 'react-swipeable';
import './style.css';

const SwipeableOptions = {
  delta: 80,
  preventDefaultTouchmoveEvent: true,
  trackTouch: true,
  trackMouse: true,
  rotationAngle: 20,
};

const Swiper = ({
  children,
  handlerLeftSwipes,
  handlerRightSwipes,
  handlerVerticalSwipes,
  className,
}) => {
  return (
    <Swipeable
      className={`swipe-element${className && ` ${className}`}`}
      onSwipedRight={e => handlerRightSwipes(e)}
      onSwipedLeft={e => handlerLeftSwipes(e)}
      onSwipedDown={e => handlerVerticalSwipes(e)}
      {...SwipeableOptions}
    >
      {children}
    </Swipeable>
  );
};

Swiper.propTypes = {
  children: propTypes.node.isRequired,
  className: propTypes.string,
  handlerLeftSwipes: propTypes.func,
  handlerRightSwipes: propTypes.func,
  handlerVerticalSwipes: propTypes.func,
};
Swiper.defaultProps = {
  className: '',
  handlerLeftSwipes: () => {},
  handlerRightSwipes: () => {},
  handlerVerticalSwipes: () => {},
};
export default Swiper;
