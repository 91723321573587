const namespace = 'ROZKLAD';

export const CURRENT_ID = `CURRENT_ID_${namespace}`;
export const CURRENT_DATE = `CURRENT_DATE_${namespace}`;
export const GET_RESPONSE_ALL = `GET_RESPONSE_ALL_${namespace}`;
export const GET_RESPONSE_DETAILED = `GET_RESPONSE_DETAILED_${namespace}`;
export const ROADSHOW = `ROADSHOW_${namespace}`;
export const GET_ROADSHOWS = `GET_ROADSHOWS_${namespace}`;
export const GET_APPOINTMENTS = `GET_APPOINTMENTS_${namespace}`;
export const GET_ROADSHOW = `GET_ROADSHOW_${namespace}`;
export const GET_APPOINTMENT = `GET_APPOINTMENT_${namespace}`;
export const GET_RANGES = `GET_RANGES_${namespace}`;
export const CUR_ID_APPOINTMENT = `CUR_ID_APPOINTMENT_${namespace}`;
export const CUR_ID_ROADSHOW = `CUR_ID_ROADSHOW_${namespace}`;

export const LOGOUT = `LOGOUT_${namespace}`;
