/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import propTypes from 'prop-types';
import SubmitButton from '../../../../shared/components/submit-button';
import Modal from '../../../../shared/components/modal';
import './style.css';

class AppointmentEditPerson extends Component {
  static propTypes = {
    isFetching: propTypes.bool,
    handleSubmit: propTypes.func,
    handleClose: propTypes.func,
  };

  static defaultProps = {
    isFetching: false,
    handleSubmit: () => {},
    handleClose: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      position: '',
      companyName: '',
      companyLinkedinUrl: '',
      description: '',
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const { name, position, companyName, companyLinkedinUrl, description } = this.state;
    this.props.handleSubmit({
      name,
      position,
      company_name: companyName,
      company_linkedin_url: companyLinkedinUrl,
      description,
    });
  }

  handleInputChange(e) {
    const { id, value } = e.target;
    this.setState({ [id]: value });
  }

  render() {
    return (
      <Modal>
        <form className="edit-appointment" onSubmit={this.handleFormSubmit}>
          <div className="edit-fields edit-fields-person">
            <h4>Person</h4>
            <input
              type="text"
              placeholder="Enter name"
              id="name"
              required
              value={this.state.name}
              onChange={this.handleInputChange}
            />
            <input
              type="text"
              placeholder="Enter position"
              id="position"
              required
              value={this.state.position}
              onChange={this.handleInputChange}
            />
            <input
              type="text"
              placeholder="Enter company name"
              id="companyName"
              value={this.state.companyName}
              onChange={this.handleInputChange}
            />
            <input
              type="url"
              placeholder="Enter company LinkedIn"
              id="companyLinkedinUrl"
              value={this.state.companyLinkedinUrl}
              onChange={this.handleInputChange}
            />
            <textarea
              rows={3}
              placeholder="Enter description"
              id="description"
              required
              value={this.state.description}
              onChange={this.handleInputChange}
            />
          </div>
          <SubmitButton
            disabled={this.props.isFetching}
            isFetching={this.props.isFetching}
            value="Create"
          />
          <button
            type="button"
            className="btn-close"
            onClick={e => {
              e.preventDefault();
              this.props.handleClose();
            }}
          >
            <i className="fas fa-times fa-2x" />
          </button>
        </form>
      </Modal>
    );
  }
}

export default AppointmentEditPerson;
