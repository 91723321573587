import * as FETCH from './constants';

function fetchReducer(
  state = {
    isFetching: false,
    responseFailed: false,
    errorMessage: '',
  },
  action
) {
  switch (action.type) {
    case FETCH.REQUEST:
      return {
        isFetching: true,
        responseFailed: false,
        errorMessage: '',
      };
    case FETCH.RESPONSE_SUCCESS:
      return {
        isFetching: false,
        responseFailed: false,
        errorMessage: '',
      };
    case FETCH.RESPONSE_FAIL:
      return {
        isFetching: false,
        responseFailed: true,
        errorMessage: action.err,
      };
    default:
      return state;
  }
}

export default fetchReducer;
