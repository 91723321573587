import React from 'react';
// import propTypes from 'prop-types';
import SettingsLink from '../links/settings';
// import LogoutLink from '../../../../shared/components/links/logout';
import RoadshowsLink from '../links/roadshows';
// import ShowFilesLink from '../../../../shared/components/links/files-provider';
// import AddLink from '../../../../shared/components/links/add-link';

const Navbar = () => (
  // {
  // handleFile,
  // hasFile,
  // handleClickPopup,
  // disableAddFiles,
  // disableShowFiles,
  // }
  <div className="icons-group">
    <RoadshowsLink />
    <div className="icon-link logo">
      <img className="logo" src={`${process.env.PUBLIC_URL}/destination-icon.png`} alt="Logo" />
    </div>
    {/* <ShowFilesLink handleClick={handleClickPopup} disabled={disableShowFiles} />
    <AddLink type="large" handleFile={handleFile} hasFile={hasFile} disabled={disableAddFiles} /> */}
    <SettingsLink />
    {/* <LogoutLink /> */}
  </div>
);

// LinksGroup.propTypes = {
//   handleClickPopup: propTypes.func,
//   handleFile: propTypes.func,
//   hasFile: propTypes.bool,
//   disableAddFiles: propTypes.bool,
//   disableShowFiles: propTypes.bool,
// };

// LinksGroup.defaultProps = {
//   handleClickPopup: () => {},
//   handleFile: () => {},
//   hasFile: false,
//   disableAddFiles: false,
//   disableShowFiles: false,
// };

export default Navbar;
