/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { formatToISO, afterDate, beforeDate } from '../../../../shared/utils/date';
import SubmitButton from '../../../../shared/components/submit-button';
import Modal from '../../../../shared/components/modal';

class AppointmentEditTime extends Component {
  static propTypes = {
    range: propTypes.shape({
      min: propTypes.string,
      max: propTypes.string,
    }),
    isFetching: propTypes.bool,
    handleSubmit: propTypes.func,
    handleClose: propTypes.func,
  };

  static defaultProps = {
    range: {
      min: '',
      max: '',
    },
    isFetching: false,
    handleSubmit: () => {},
    handleClose: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedTime: props.range.min,
    };
    this.handleTimePickerChange = this.handleTimePickerChange.bind(this);
    this.disabledHours = this.disabledHours.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleTimePickerChange(value) {
    const { min, max } = this.props.range;
    let time = value;
    if (afterDate(value, max)) {
      time = max;
    } else if (beforeDate(value, min)) {
      time = min;
    }
    this.setState({
      selectedTime: moment(time)
        .utc()
        .seconds(0),
    });
  }

  disabledHours() {
    const { min, max } = this.props.range;
    const minHour = moment(min)
      .utc()
      .hours();
    const maxHour = moment(max)
      .utc()
      .hours();
    const disabledHours = [];
    for (let i = 0; i < 24; i++) {
      if (i < minHour || i > maxHour) {
        disabledHours.push(i);
      }
    }
    return disabledHours;
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.props.handleSubmit({ startDateTime: formatToISO(this.state.selectedTime) });
  }

  render() {
    return (
      <Modal>
        <form className="edit-appointment" onSubmit={this.handleFormSubmit}>
          <div className="edit-fields">
            <h4>Time</h4>
            <TimePicker
              value={moment(this.state.selectedTime).utc()}
              placeholder="Select time"
              showSecond={false}
              format="HH:mm"
              disabledHours={this.disabledHours}
              hideDisabledOptions
              onChange={this.handleTimePickerChange}
              minuteStep={15}
              inputReadOnly
              allowEmpty={false}
            />
          </div>
          <SubmitButton
            disabled={this.props.isFetching}
            isFetching={this.props.isFetching}
            value="Change"
          />
          <button
            type="button"
            className="btn-close"
            onClick={e => {
              e.preventDefault();
              this.props.handleClose();
            }}
          >
            <i className="fas fa-times fa-2x" />
          </button>
        </form>
      </Modal>
    );
  }
}

export default AppointmentEditTime;
