import React, { Component } from 'react';
import propTypes from 'prop-types';

class Drag extends Component {
  dragStartX = 0;

  diffX = 0;

  dragged = false;

  fpsInterval = 1000 / 60;

  startTime;

  static propTypes = {
    dragCallback: propTypes.func,
    elementRef: propTypes.func,
    treshold: propTypes.number,
    offset: propTypes.number,
    className: propTypes.string,
    children: propTypes.node.isRequired,
    left: propTypes.bool,
    right: propTypes.bool,
  };

  static defaultProps = {
    dragCallback: () => {},
    elementRef: () => {},
    treshold: 0,
    offset: 0,
    className: '',
    left: false,
    right: false,
  };

  constructor(props) {
    super(props);
    this.item = React.createRef();
    this.handleDragStartMouse = this.handleDragStartMouse.bind(this);
    this.handleDragStartTouch = this.handleDragStartTouch.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleDragEndMouse = this.handleDragEndMouse.bind(this);
    this.handleDragEndTouch = this.handleDragEndTouch.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.updatePosition = this.updatePosition.bind(this);
    this.handleDragCancel = this.handleDragCancel.bind(this);
  }

  componentDidMount() {
    const item = this.item.current;
    this.props.elementRef(item);
    item.addEventListener('mouseup', this.handleDragEndMouse, { cancelable: true, passive: true });
    item.addEventListener('touchend', this.handleDragEndTouch, { cancelable: true, passive: true });
    item.addEventListener('touchcancel', this.handleDragCancel, {
      cancelable: true,
      passive: true,
    });
  }

  componentWillUnmount() {
    const item = this.item.current;
    item.removeEventListener('mouseup', this.handleDragEndMouse);
    item.removeEventListener('touchend', this.handleDragEndTouch);
    item.removeEventListener('touchcancel', this.handleDragCancel);
    window.cancelAnimationFrame(this.updatePosition);
  }

  handleDragStartMouse(e) {
    e.stopPropagation();
    const item = this.item.current;
    this.handleDragStart(e.clientX);
    item.addEventListener('mousemove', this.handleMouseMove, { cancelable: true, passive: true });
  }

  handleDragStartTouch(e) {
    e.stopPropagation();
    const touch = e.targetTouches[0];
    const item = this.item.current;
    this.handleDragStart(touch.clientX);
    item.addEventListener('touchmove', this.handleTouchMove, { cancelable: true, passive: true });
  }

  handleDragStart(clientX) {
    this.dragged = true;
    this.dragStartX = clientX;
    this.startTime = Date.now();
    requestAnimationFrame(this.updatePosition);
  }

  handleMouseMove(e) {
    e.stopPropagation();
    this.diffX = e.clientX - this.dragStartX;
  }

  handleTouchMove(e) {
    e.stopPropagation();
    const touch = e.targetTouches[0];
    this.diffX = touch.clientX - this.dragStartX;
  }

  handleDragEndMouse(e) {
    e.stopPropagation();
    const item = this.item.current;
    item.removeEventListener('mousemove', this.handleMouseMove);
    this.handleDragEnd(e);
  }

  handleDragEndTouch(e) {
    e.stopPropagation();
    const item = this.item.current;
    item.removeEventListener('touchmove', this.handleTouchMove);
    this.handleDragEnd(e);
  }

  handleDragEnd() {
    const { dragCallback, treshold, offset, left, right } = this.props;
    if (this.dragged) {
      this.dragged = false;
      if (left && this.diffX > 0 && this.diffX > offset * (treshold || 1.5)) {
        this.diffX = offset;
        dragCallback(this.diffX);
      } else if (right && this.diffX < 0 && Math.abs(this.diffX) > offset * (treshold || 1.5)) {
        this.diffX = -offset;
        dragCallback(this.diffX);
      } else {
        this.diffX = 0;
      }
      this.item.current.style.transform = `translateX(${this.diffX}px)`;
      window.cancelAnimationFrame(this.updatePosition);
    }
  }

  handleDragCancel() {
    this.item.current.style.transform = `translateX(0)`;
  }

  updatePosition() {
    if (this.dragged) requestAnimationFrame(this.updatePosition);
    const { left, right } = this.props;
    const now = Date.now();
    const elapsed = now - this.startTime;

    if (this.dragged && elapsed > this.fpsInterval) {
      if (left && this.diffX > 0) this.item.current.style.transform = `translateX(${this.diffX}px)`;
      if (right && this.diffX < 0)
        this.item.current.style.transform = `translateX(${this.diffX}px)`;
      this.startTime = Date.now();
    }
  }

  render() {
    const { children, className } = this.props;
    return (
      <div
        role="menuitem"
        tabIndex={0}
        ref={this.item}
        onMouseDown={this.handleDragStartMouse}
        onTouchStart={this.handleDragStartTouch}
        className={className}
      >
        {children}
      </div>
    );
  }
}
export default Drag;
