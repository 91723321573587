import moment from 'moment';

const dayFormat = 'Do MMMM';
const dateFormat = 'YYYY-MM-DD';
const timeFormat = 'HH:mm';
const rangeFormat = 'MMM D';
const rangeEndFormat = 'D';
const UNITS = 'month';

moment.updateLocale(moment.locale(), { invalidDate: '' });

// const dateToCurrentUTC = date => moment(date).utcOffset(moment(new Date()).utcOffset());
const dateToCurrentUTC = date => moment(date).utc();

export const utcOffset = () => moment().utcOffset();
export const formatToDate = date => dateToCurrentUTC(date).format(dateFormat);
export const formatToRanges = (to, from) =>
  `${dateToCurrentUTC(to).format(timeFormat)} - ${dateToCurrentUTC(from).format(timeFormat)}`;
export const formatToTime = date => dateToCurrentUTC(date).format(timeFormat);
export const formatToDays = date => dateToCurrentUTC(date).format(dayFormat);
export const formatToDay = date => dateToCurrentUTC(date).format(rangeEndFormat);
export const formatToISO = date => dateToCurrentUTC(date).toISOString();
export const formatToTimeStamp = date => dateToCurrentUTC(date).format('x');
export const formatToDateRange = (startDate = '', endDate = '') =>
  `${dateToCurrentUTC(startDate).format(rangeFormat)} - ${
    dateToCurrentUTC(startDate).isSame(endDate, UNITS)
      ? dateToCurrentUTC(endDate).format(rangeEndFormat)
      : dateToCurrentUTC(endDate).format(rangeFormat)
  }`;

export const sameDay = (firstDate, secondDate) =>
  dateToCurrentUTC(firstDate).isSame(secondDate, 'day');
export const dayIsBetween = (current, startDate, endDate) =>
  dateToCurrentUTC(current).isBetween(startDate, endDate, null, '[]');
export const afterDate = (current, checked) => dateToCurrentUTC(current).isAfter(checked);
export const beforeDate = (current, checked) => dateToCurrentUTC(current).isBefore(checked);
export const addDay = date => dateToCurrentUTC(date).add(1, 'd');
export const addDayFormat = date =>
  dateToCurrentUTC(date)
    .add(1, 'd')
    .toISOString();
export const subDay = date => dateToCurrentUTC(date).subtract(1, 'd');
export const subDayFormat = date =>
  dateToCurrentUTC(date)
    .subtract(1, 'd')
    .toISOString();
export const addDays = (date, i) => dateToCurrentUTC(date).add(i, 'd');
export const subtractMinutes = (date, i) => dateToCurrentUTC(date).subtract(i, 'm');

export const addMinutes = (date, i) => dateToCurrentUTC(date).add(i, 'm');
