import React, { Component } from 'react';
import propTypes from 'prop-types';
import Page from '../../../shared/components/page';
import List from '../../../shared/components/render-list';
import RoadshowItem from '../components/item';
import Message from '../../../shared/components/message';
import './style.css';

class RoadshowsPage extends Component {
  componentDidMount() {
    const { fetchRoadshows } = this.props;
    fetchRoadshows();
  }

  render() {
    const { roadshows, setCurRoadshow } = this.props;
    const handleItemClick = id => setCurRoadshow(id);
    return (
      <Page>
        {roadshows.length ? (
          <List
            list={roadshows}
            className="roadshow-list"
            ListItem={RoadshowItem}
            itemProps={{ handleItemClick }}
          />
        ) : (
          <div className="roadshow-page">
            <Message text="No roadshows" />
          </div>
        )}
      </Page>
    );
  }
}
RoadshowsPage.propTypes = {
  fetchRoadshows: propTypes.func,
  setCurRoadshow: propTypes.func,
  roadshows: propTypes.array,
};
RoadshowsPage.defaultProps = {
  fetchRoadshows: () => {},
  setCurRoadshow: () => {},
  roadshows: [],
};

export default RoadshowsPage;
