import React from 'react';
import propTypes from 'prop-types';
import LinkedinLink from '../../../../shared/components/links/linkedin';
import HubspotLink from '../../../../shared/components/links/hubspot';
import MailLink from '../../../../shared/components/links/mail';
import PhoneLink from '../../../../shared/components/links/phone';
import AddLink from '../../../../shared/components/links/add-link';

const LinksGroup = ({
  links: { linkedinUrl, hubspotUrl, phoneNumber, email },
  handleFile,
  hasFile,
}) => (
  <>
    <LinkedinLink link={linkedinUrl || 'false'} />
    <HubspotLink link={hubspotUrl || 'false'} />
    <AddLink type="large" handleFile={handleFile} hasFile={hasFile} />
    <MailLink link={`${email && `mailto:${email}`}`} />
    <PhoneLink link={`${phoneNumber && `tel:${phoneNumber}`}`} />
  </>
);

LinksGroup.propTypes = {
  links: propTypes.object,
  handleFile: propTypes.func,
  hasFile: propTypes.bool,
};

LinksGroup.defaultProps = {
  links: {},
  handleFile: () => {},
  hasFile: false,
};

export default LinksGroup;
