import React from 'react';
import propTypes from 'prop-types';
import './style.css';

const Popup = ({ show, message: { title, text }, handleCallback }) => {
  return (
    show && (
      <div className="popup--page">
        <div className="popup">
          {title && (
            <>
              <h4>{title}</h4>
              <hr />
            </>
          )}
          <p>{text}</p>
          <div className="button-group">
            <button
              type="button"
              onTouchStart={e => e.currentTarget.classList.add('active')}
              onTouchEnd={e => e.currentTarget.classList.remove('active')}
              onClick={() => handleCallback(true)}
            >
              Yes
            </button>
            <button
              type="button"
              onTouchStart={e => e.currentTarget.classList.add('active')}
              onTouchEnd={e => e.currentTarget.classList.remove('active')}
              onClick={() => handleCallback(false)}
            >
              No
            </button>
          </div>
        </div>
      </div>
    )
  );
};
Popup.propTypes = {
  show: propTypes.bool,
  message: propTypes.shape({
    title: propTypes.string,
    text: propTypes.string.isRequired,
  }),
  handleCallback: propTypes.func,
};
Popup.defaultProps = {
  show: false,
  message: {
    title: '',
  },
  handleCallback: () => {},
};
export default Popup;
