import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import ListItem from '../list-item';
import { formatToISO } from '../../../../shared/utils/date';

const List = ({ handleEditTime, appointments, freeSpots, pickedDate, appointmentId }) => {
  const renderAddButton = endDateTime => {
    const freeSpot = freeSpots.find(spot => spot.start === endDateTime);
    if (freeSpot) {
      return (
        <div
          className="btn-add"
          role="button"
          tabIndex="0"
          onClick={() => handleEditTime({ min: freeSpot.start, max: freeSpot.end })}
          onKeyPress={() => handleEditTime({ min: freeSpot.start, max: freeSpot.end })}
        >
          <i className="far fa-plus-square fa-3x" />
        </div>
      );
    }
    return null;
  };

  return (
    <ul className="list">
      {appointments.length ? (
        <>
          <div
            className="btn-add"
            role="button"
            tabIndex="0"
            onClick={() => handleEditTime({ min: freeSpots[0].start, max: freeSpots[0].end })}
            onKeyPress={() => handleEditTime({ min: freeSpots[0].start, max: freeSpots[0].end })}
          >
            <i className="far fa-plus-square fa-3x" />
          </div>
          {appointments.map(
            ({ id, status, startDateTime, endDateTime, person: { name, companyName, photo } }) => (
              <li key={id}>
                <ListItem
                  appointment={{
                    id,
                    status,
                    startDateTime,
                    name,
                    companyName,
                    photo,
                  }}
                  current={id === appointmentId}
                />
                {renderAddButton(endDateTime)}
              </li>
            )
          )}
        </>
      ) : (
        <div
          className="btn-add"
          role="button"
          tabIndex="0"
          onClick={() =>
            handleEditTime({
              min: formatToISO(
                moment(pickedDate)
                  .utc()
                  .hours(0)
              ),
              max: formatToISO(
                moment(pickedDate)
                  .utc()
                  .hours(23)
              ),
            })
          }
          onKeyPress={() =>
            handleEditTime({
              min: formatToISO(
                moment(pickedDate)
                  .utc()
                  .hours(0)
              ),
              max: formatToISO(
                moment(pickedDate)
                  .utc()
                  .hours(23)
              ),
            })
          }
        >
          <i className="far fa-plus-square fa-3x" />
        </div>
      )}
    </ul>
  );
};

List.propTypes = {
  handleEditTime: propTypes.func.isRequired,
  appointments: propTypes.arrayOf(propTypes.object),
  freeSpots: propTypes.arrayOf(propTypes.object),
  pickedDate: propTypes.string,
  appointmentId: propTypes.string,
};
List.defaultProps = {
  appointments: [{}],
  freeSpots: [{}],
  pickedDate: '',
  appointmentId: '',
};

export default List;
