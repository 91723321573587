import React from 'react';
import { Link } from 'react-router-dom';
import './icon-link.css';

const SettingsLink = () => (
  <Link className="icon-link" to="/settings">
    <i className="fas fa-user-cog" />
  </Link>
);

export default SettingsLink;
