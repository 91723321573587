import { connect } from 'react-redux';
import Appointment from './component';
import {
  // selectAppointmentById,
  selectRoadshowById,
  selectPrevAppointmentById,
  selectNextAppointmentById,
  selectAppointmentsByDates,
} from '../../../store/appointments/selectors';
import {
  deleteMedia,
  fetchAppointment,
  fetchActualMeetSlots,
  fetchActualMeetSlotsByLocation,
  sendMedia,
  getPickedDate,
  fetchRoadshowAppointments,
} from '../../../store/appointments/actions';

const mapStateToProps = ({ fetchReducer, appointmentsReducer }) => ({
  // appointment: selectAppointmentById(appointmentsReducer),
  appointment: appointmentsReducer.appointment,
  roadshow: selectRoadshowById(appointmentsReducer),
  ranges: appointmentsReducer.ranges,
  isFetching: fetchReducer.isFetching,
  errorMessage: fetchReducer.errorMessage,
  responseFailed: fetchReducer.responseFailed,
  id: appointmentsReducer.idOfAppointment,
  nextId: selectNextAppointmentById(appointmentsReducer),
  prevId: selectPrevAppointmentById(appointmentsReducer),
  appointments: selectAppointmentsByDates(appointmentsReducer),
  roadshowId: appointmentsReducer.idOfRoadshow,
  pickedDate: appointmentsReducer.pickedDate,
});

const mapDispatchToProps = dispatch => ({
  handlePickedDate: date => dispatch(getPickedDate(date)),
  fetchAppointment: id => dispatch(fetchAppointment(id)),
  fetchActualMeetSlots: dates => dispatch(fetchActualMeetSlots(dates)),
  fetchActualMeetSlotsByLocation: (location, dates) =>
    dispatch(fetchActualMeetSlotsByLocation(location, dates)),
  deleteMedia: id => dispatch(deleteMedia('appointments', id)),
  sendMedia: file => dispatch(sendMedia('appointments', file)),
  fetchAppointments: (id, date) => dispatch(fetchRoadshowAppointments(id, date)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Appointment);
