import * as FETCH from './constants';

export function onRequest() {
  return { type: FETCH.REQUEST };
}
export function onResponseSuccess() {
  return { type: FETCH.RESPONSE_SUCCESS };
}
export function onResponseFail(err) {
  return { type: FETCH.RESPONSE_FAIL, err };
}
