import React, { Component } from 'react';
import propTypes from 'prop-types';
import Link from '../../../../shared/components/link';
import avatarPlaceholder from '../../../../assets/avatar-placeholder.png';
import './style.css';

class PersonInfo extends Component {
  static propTypes = {
    handlePhotoClick: propTypes.func,
    handleButton: propTypes.func,
    personInfo: propTypes.shape({
      name: propTypes.string,
      companyName: propTypes.string,
      linkedinUrl: propTypes.string,
      position: propTypes.string,
      photo: propTypes.string,
    }),
  };

  static defaultProps = {
    handlePhotoClick: () => {},
    handleButton: () => {},
    personInfo: {
      name: '',
      linkedinUrl: '',
      companyName: '',
      position: '',
      photo: '',
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      active: false,
      showDropdown: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.handlePhotoClick();
    this.setState(prevState => ({ active: !prevState.active }));
  }

  render() {
    const {
      personInfo: { photo, name, companyName, companyUrl, position },
      handleButton,
    } = this.props;
    const { active, showDropdown } = this.state;
    return (
      <div className="person-info">
        <div className="person-info--image">
          <div
            className={active ? 'active' : undefined}
            role="link"
            tabIndex="0"
            onClick={this.handleClick}
            onKeyPress={this.handleClick}
          >
            <div
              style={{
                backgroundImage: `url(${
                  !(photo === undefined || photo == null) ? photo : avatarPlaceholder
                })`,
              }}
              className="person-info--image-container"
            />
          </div>
        </div>
        <div className="person-info--details">
          <h1 className="person-info--name">{name}</h1>
          <h2>{position}</h2>
          {companyUrl && (
            <Link href={companyUrl} linkText={companyName || 'Link to company site'} />
          )}
        </div>
        <div
          className="action"
          role="button"
          tabIndex="0"
          // onClick={() => handleButton()}
          // onKeyPress={() => handleButton()}
          onClick={() => this.setState(prevState => ({ showDropdown: !prevState.showDropdown }))}
          onKeyPress={() => this.setState(prevState => ({ showDropdown: !prevState.showDropdown }))}
        >
          <i className="fas fa-ellipsis-v" />
          <div className={`ellipsis-dropdown ${!showDropdown ? 'hidden' : ''}`}>
            <div className="ellipsis-dropdown-panel">
              <span
                className="ellipsis-dropdown-item"
                role="button"
                tabIndex="0"
                onClick={handleButton}
                onKeyPress={handleButton}
              >
                Edit
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PersonInfo;
