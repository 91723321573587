/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import propTypes from 'prop-types';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import SubmitButton from '../../../../shared/components/submit-button';
import Modal from '../../../../shared/components/modal';
import Map, { getPlaceByCoords } from '../../../../shared/components/map';

import './style.css';

class AppointmentEditAddress extends Component {
  static propTypes = {
    isFetching: propTypes.bool,
    handleSubmit: propTypes.func,
    handleClose: propTypes.func,
  };

  static defaultProps = {
    isFetching: false,
    handleSubmit: () => {},
    handleClose: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      address: '',
      position: { lat: 51.5, lng: 0 },
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleMapClick = async (...props) => {
    const [
      map,
      ie,
      {
        latLng: { lat, lng },
      },
    ] = props;
    const coords = { lat: lat(), lng: lng() };
    const { formatted_address: address } = await getPlaceByCoords(coords);
    this.setState({ position: coords, address });
  };

  handleFormSubmit(e) {
    e.preventDefault();
    this.props.handleSubmit(this.state.address, this.state.position);
  }

  handleChange(address) {
    this.setState({ address });
  }

  async handleSelect(address) {
    try {
      const results = await geocodeByAddress(address);
      const position = await getLatLng(results[0]);
      this.setState({ address, position });
    } catch (error) {
      console.error('Get address by place, error: ', error);
    }
  }

  renderPlacesAutocomplete = ({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
    <div className="autocomplete-root">
      <input {...getInputProps({ placeholder: 'Search address...', required: true })} />
      <div className="autocomplete-dropdown-container" hidden={!this.state.address.length}>
        {loading && <div className="suggestion-loading">Loading...</div>}
        {suggestions.map(suggestion => {
          const className = suggestion.active ? 'suggestion-item active' : 'suggestion-item';
          return (
            <div {...getSuggestionItemProps(suggestion, { className })}>
              {/* <span>{suggestion.description}</span> */}
              <span>
                <strong>{suggestion.formattedSuggestion.mainText}</strong>,{' '}
                {suggestion.formattedSuggestion.secondaryText}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );

  render() {
    return (
      <Modal>
        <form className="edit-appointment" onSubmit={this.handleFormSubmit}>
          <div className="edit-fields">
            <h4>Venue</h4>
            <PlacesAutocomplete
              value={this.state.address}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
            >
              {this.renderPlacesAutocomplete}
            </PlacesAutocomplete>
            {this.state.position && (
              <div style={{ margin: '20px auto', width: '100%', height: '250px' }}>
                <Map
                  style={{ width: '100%', height: '250px' }}
                  formatedAddress={this.state.address}
                  marker={this.state.position}
                  onClick={this.handleMapClick}
                />
              </div>
            )}
          </div>
          <SubmitButton
            disabled={this.props.isFetching}
            isFetching={this.props.isFetching}
            value="Next"
          />
          <button
            type="button"
            className="btn-close"
            onClick={e => {
              e.preventDefault();
              this.props.handleClose();
            }}
          >
            <i className="fas fa-times fa-2x" />
          </button>
        </form>
      </Modal>
    );
  }
}

export default AppointmentEditAddress;
