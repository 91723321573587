import React from 'react';
import propTypes from 'prop-types';
import { setClass } from '../../utils/base';
import './style.css';

const Header = ({ className, children }) => (
  <header className={`header${setClass(className)}`}>{children}</header>
);

Header.propTypes = {
  children: propTypes.node.isRequired,
  className: propTypes.string,
};
Header.defaultProps = {
  className: '',
};
export default Header;
