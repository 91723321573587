/* eslint-disable no-nested-ternary */

import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import ListItem from '../list-item';
// import Message from '../../../../shared/components/message';
import { formatToISO } from '../../../../shared/utils/date';
import './style.css';

const List = ({
  appointments,
  handleRoute,
  handleEditData,
  handleEditTime,
  freeSpots,
  pickedDate,
  isSelectingDateTime,
}) => {
  const renderAddButton = endDateTime => {
    const freeSpot = freeSpots.find(spot => spot.start === endDateTime);
    if (freeSpot) {
      return (
        <div
          className="btn-add"
          role="button"
          tabIndex="0"
          onClick={() => handleEditTime({ min: freeSpot.start, max: freeSpot.end })}
          onKeyPress={() => handleEditTime({ min: freeSpot.start, max: freeSpot.end })}
        >
          <i className="far fa-plus-square fa-3x" />
        </div>
      );
    }
    return null;
  };

  return (
    <ul className="list">
      {appointments.length ? (
        <>
          {isSelectingDateTime && (
            <div
              className="btn-add"
              role="button"
              tabIndex="0"
              onClick={() => handleEditTime({ min: freeSpots[0].start, max: freeSpots[0].end })}
              onKeyPress={() => handleEditTime({ min: freeSpots[0].start, max: freeSpots[0].end })}
            >
              <i className="far fa-plus-square fa-3x" />
            </div>
          )}
          {appointments.map(
            ({ id, status, startDateTime, endDateTime, person: { name, companyName, photo } }) => (
              <li key={id}>
                <ListItem
                  key={id}
                  appointment={{
                    id,
                    status,
                    startDateTime,
                    name,
                    companyName,
                    photo,
                  }}
                  handleRoute={handleRoute}
                  isSelectingDateTime={isSelectingDateTime}
                />
                {isSelectingDateTime ? renderAddButton(endDateTime) : null}
              </li>
            )
          )}
          {!isSelectingDateTime && (
            <div
              className="btn-add"
              role="button"
              tabIndex="0"
              onClick={handleEditData}
              onKeyPress={handleEditData}
            >
              <i className="far fa-plus-square fa-3x" />
            </div>
          )}
        </>
      ) : /* <Message text="No meetings for this day" /> */
      !isSelectingDateTime ? (
        <div
          className="btn-add"
          role="button"
          tabIndex="0"
          onClick={handleEditData}
          onKeyPress={handleEditData}
        >
          <i className="far fa-plus-square fa-3x" />
        </div>
      ) : (
        <div
          className="btn-add"
          role="button"
          tabIndex="0"
          onClick={() =>
            handleEditTime({
              min: formatToISO(
                moment(pickedDate)
                  .utc()
                  .hours(0)
              ),
              max: formatToISO(
                moment(pickedDate)
                  .utc()
                  .hours(23)
              ),
            })
          }
          onKeyPress={() =>
            handleEditTime({
              min: formatToISO(
                moment(pickedDate)
                  .utc()
                  .hours(0)
              ),
              max: formatToISO(
                moment(pickedDate)
                  .utc()
                  .hours(23)
              ),
            })
          }
        >
          <i className="far fa-plus-square fa-3x" />
        </div>
      )}
    </ul>
  );
};

List.propTypes = {
  appointments: propTypes.arrayOf(propTypes.object),
  handleRoute: propTypes.func.isRequired,
  handleEditData: propTypes.func.isRequired,
  handleEditTime: propTypes.func.isRequired,
  freeSpots: propTypes.arrayOf(propTypes.object),
  pickedDate: propTypes.string,
  isSelectingDateTime: propTypes.bool,
};
List.defaultProps = {
  appointments: [{}],
  freeSpots: [{}],
  pickedDate: '',
  isSelectingDateTime: false,
};

export default List;
