import React from 'react';
import propTypes from 'prop-types';
import { LinkedIn } from 'react-linkedin-login-oauth2';

const LinkedinAuth = ({ handleAuth, handleFailure, token }) => (
  <LinkedIn
    className="social-login"
    clientId={token}
    state=""
    scope="r_liteprofile r_emailaddress w_member_social"
    redirectUri={`${document.location.origin}/linkedin`}
    onSuccess={res => handleAuth('linkedin', res)}
    onFailure={res => handleFailure('linkedin', res)}
  >
    <i className="fab fa-linkedin" />
    <span> Login with LinkedIn</span>
  </LinkedIn>
);

LinkedinAuth.propTypes = {
  handleAuth: propTypes.func.isRequired,
  handleFailure: propTypes.func.isRequired,
  token: propTypes.string.isRequired,
};
export default LinkedinAuth;
