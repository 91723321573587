import React, { Component } from 'react';
import propTypes from 'prop-types';
import deepEqual from 'deep-equal';
import { CSSTransition } from 'react-transition-group';
import Header from '../../../shared/components/header';
import Navbar from '../../../shared/components/navbar';
import Preloader from '../../../shared/components/preloader';
import Popup from '../../../shared/components/popup';
import Notification from '../../../shared/components/notification';
import Swiper from '../../../shared/components/swiper';
import File from '../../../shared/components/user-file';
import PersonInfo from '../components/person-info';
import AppointmentFiles from '../components/appointment-files';
import MeetInfo from '../components/meet-info';
import AppointmentInfo from '../components/appointment-info';
import LinksGroup from '../components/links-group';
import AppointmentEditAddress from '../components/edit-address';
import RoadshowItem from '../../roadshows/components/item';
import AppointmentEditTime from '../components/edit-time';
import API from '../../../api';
import DatePick from '../../../shared/components/date-pick';
import List from '../components/list';
import {
  addDayFormat,
  afterDate,
  beforeDate,
  dayIsBetween,
  sameDay,
  subDayFormat,
} from '../../../shared/utils/date';
import './style.css';

class Appointment extends Component {
  static propTypes = {
    appointment: propTypes.object,
    ranges: propTypes.array,
    fetchAppointment: propTypes.func,
    fetchActualMeetSlots: propTypes.func,
    fetchActualMeetSlotsByLocation: propTypes.func,
    deleteMedia: propTypes.func,
    sendMedia: propTypes.func,
    handlePickedDate: propTypes.func,
    history: propTypes.shape({
      push: propTypes.func.isRequired,
    }).isRequired,
    id: propTypes.string,
    prevId: propTypes.string,
    nextId: propTypes.string,
    isFetching: propTypes.bool.isRequired,
    match: propTypes.shape({ params: propTypes.object.isRequired }).isRequired,
    roadshow: propTypes.object,
    responseFailed: propTypes.bool,
    errorMessage: propTypes.string,
    fetchAppointments: propTypes.func,
    appointments: propTypes.arrayOf(propTypes.object),
    roadshowId: propTypes.string,
    pickedDate: propTypes.string,
  };

  static defaultProps = {
    handlePickedDate: () => {},
    ranges: [],
    appointment: {},
    roadshow: {},
    id: '',
    prevId: '',
    nextId: '',
    fetchActualMeetSlots: () => {},
    fetchActualMeetSlotsByLocation: () => {},
    fetchAppointment: () => {},
    deleteMedia: () => {},
    sendMedia: () => {},
    responseFailed: false,
    errorMessage: '',
    fetchAppointments: () => {},
    appointments: [{}],
    roadshowId: '',
    pickedDate: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      photo: {
        show: false,
      },
      file: {
        hasFile: false,
        file: {},
        localFile: {},
      },
      popup: {
        title: '',
        text: '',
        show: false,
      },
      isEditingAddress: false,
      selectedAddress: '',
      selectedPosition: {},
      isSelectingDateTime: false,
      isEditingTime: false,
      selectedTimeRange: {},
      googleApiError: '',
    };
    this.handleChangeAppointment = this.handleChangeAppointment.bind(this);
    this.handleChangeAppointmentAddress = this.handleChangeAppointmentAddress.bind(this);
    this.handleRoute = this.handleRoute.bind(this);
    this.handleEditData = this.handleEditData.bind(this);
    this.handlePhotoClick = this.handlePhotoClick.bind(this);
    this.handleVerticalSwipe = this.handleVerticalSwipe.bind(this);
    this.handleRightSwipe = this.handleRightSwipe.bind(this);
    this.handleLeftSwipe = this.handleLeftSwipe.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleFileDelete = this.handleFileDelete.bind(this);
    this.handleLocalDelete = this.handleLocalDelete.bind(this);
    this.handleSettingsPage = this.handleSettingsPage.bind(this);
    this.handlePopupState = this.handlePopupState.bind(this);
    this.handleListRightSwipe = this.handleListRightSwipe.bind(this);
    this.handleListLeftSwipe = this.handleListLeftSwipe.bind(this);
    this.handleEditTime = this.handleEditTime.bind(this);
  }

  componentDidMount() {
    const {
      fetchAppointment,
      id,
      match: { params },
      roadshowId,
      fetchAppointments,
      pickedDate,
    } = this.props;
    fetchAppointment(params.id || id);
    fetchAppointments(roadshowId, pickedDate);
  }

  componentDidUpdate(prevProps) {
    if (!deepEqual(this.props.roadshow, prevProps.roadshow)) {
      const { roadshow, pickedDate, handlePickedDate } = this.props;
      const { startDateTime, endDateTime } = roadshow;
      if (afterDate(pickedDate, endDateTime)) handlePickedDate(roadshow.startDateTime);
      if (beforeDate(pickedDate, startDateTime)) handlePickedDate(roadshow.startDateTime);
    }
  }

  handleChangeAppointment(obj) {
    const { id, handlePickedDate, fetchAppointment } = this.props;
    const data = {
      appointmentplace: {
        // address: obj.address || undefined,
        address: this.state.selectedAddress || undefined,
        position: this.state.selectedPosition,
      },
      start_datetime: obj.startDateTime || undefined,
    };
    API.modifyAppointment(id, data).then(() => {
      this.setState({ isEditingAddress: false, isEditingTime: false, isSelectingDateTime: false });
      handlePickedDate(obj.startDateTime);
      fetchAppointment(id);
    });
  }

  handleChangeAppointmentAddress(location, position) {
    const { roadshow, fetchActualMeetSlotsByLocation } = this.props;
    fetchActualMeetSlotsByLocation(location, {
      from: roadshow.startDateTime,
      to: roadshow.endDateTime,
    }).then(
      () =>
        this.setState(prevState => ({
          isEditingAddress: !prevState.isEditingAddress,
          isSelectingDateTime: true,
          selectedAddress: location,
          selectedPosition: {
            latitude: position.lat,
            longitude: position.lng,
          },
        })),
      () => {
        this.setState({ googleApiError: 'This time period is now locked for editing.' });
        setTimeout(() => this.setState({ googleApiError: '' }), 3000);
      }
    );
  }

  handleRoute(e) {
    e.stopPropagation();
    this.props.history.push('/roadshow');
  }

  handleEditData() {
    // const { roadshow, fetchActualMeetSlots } = this.props;
    // fetchActualMeetSlots({ from: roadshow.startDateTime, to: roadshow.endDateTime }).then(() =>
    //   this.setState(({ isEditingAddress }) => ({ isEditingAddress: !isEditingAddress }))
    // );
    this.setState(prevState => ({ isEditingAddress: !prevState.isEditingAddress }));
  }

  handlePhotoClick() {
    this.setState(prevState => ({ photo: { show: !prevState.photo.show } }));
  }

  handleRightSwipe() {
    const { history, fetchAppointment, prevId } = this.props;
    if (prevId === null) history.push('/roadshow');
    else {
      history.push(prevId);
      fetchAppointment(prevId);
    }
  }

  handleLeftSwipe() {
    const { history, fetchAppointment, nextId } = this.props;
    if (nextId === null) history.push('/roadshow');
    else {
      history.push(nextId);
      fetchAppointment(nextId);
    }
  }

  handleVerticalSwipe(event) {
    const {
      fetchActualMeetSlots,
      fetchAppointment,
      roadshow,
      id,
      match: { params },
    } = this.props;
    const { y: coordY } = document.querySelector('.swipe-element').getBoundingClientRect();
    if (event.absY >= 200 && coordY > 0) {
      fetchAppointment(params.id || id);
      fetchActualMeetSlots({ from: roadshow.startDateTime, to: roadshow.endDateTime });
    }
  }

  handleFile(file) {
    const {
      match: { params },
    } = this.props;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState({
        file: {
          file,
          id: params.id,
          localFile: { name: file.name, type: file.type, urlImage: reader.result },
          hasFile: true,
        },
      });
    };
  }

  handleFileUpload() {
    const {
      file: { file, id },
    } = this.state;
    const {
      sendMedia,
      fetchAppointment,
      match: { params },
    } = this.props;
    sendMedia({ file, id }).then(() => {
      this.setState({ file: { file: {}, localFile: {}, hasFile: false } });
      fetchAppointment(params.id);
    });
  }

  handleFileDelete(file) {
    this.setState({
      popup: {
        show: true,
        title: 'Confirm delete!',
        text: `Are you sure about deleting file: ${file.filename}?`,
        file,
      },
    });
  }

  handleLocalDelete() {
    this.setState({ file: { file: {}, localFile: {}, hasFile: false } });
  }

  handleSettingsPage(e) {
    const { history } = this.props;
    e.stopPropagation();
    history.push('/settings');
  }

  handlePopupState(res) {
    const { deleteMedia } = this.props;
    const {
      popup: { file },
    } = this.state;
    this.setState({ popup: { show: false, title: '', text: '', file: undefined } });
    if (res === true)
      deleteMedia(file.id).then(() => {
        const {
          responseFailed,
          fetchAppointment,
          match: { params },
        } = this.props;
        if (!responseFailed) {
          fetchAppointment(params.id);
        }
      });
  }

  handleListLeftSwipe() {
    const {
      handlePickedDate,
      pickedDate,
      roadshow: { startDateTime, endDateTime },
    } = this.props;
    if (afterDate(pickedDate, endDateTime)) handlePickedDate(endDateTime);
    if (beforeDate(pickedDate, startDateTime)) handlePickedDate(startDateTime);
    if (dayIsBetween(pickedDate, startDateTime, endDateTime)) {
      if (sameDay(pickedDate, endDateTime)) handlePickedDate(endDateTime);
      else handlePickedDate(addDayFormat(pickedDate));
    }
  }

  handleListRightSwipe() {
    const {
      handlePickedDate,
      pickedDate,
      roadshow: { startDateTime, endDateTime },
    } = this.props;
    if (afterDate(pickedDate, endDateTime)) handlePickedDate(endDateTime);
    if (beforeDate(pickedDate, startDateTime)) handlePickedDate(startDateTime);
    if (dayIsBetween(pickedDate, startDateTime, endDateTime)) {
      if (sameDay(pickedDate, startDateTime)) handlePickedDate(startDateTime);
      else handlePickedDate(subDayFormat(pickedDate));
    }
  }

  handleEditTime(obj) {
    this.setState(prevState => ({
      isEditingTime: !prevState.isEditingTime,
      selectedTimeRange: obj ? { ...obj } : prevState.selectedTimeRange,
    }));
  }

  render() {
    const {
      responseFailed,
      errorMessage,
      appointment: { place, person, media, startDateTime, status },
      roadshow,
      ranges,
      isFetching,
      appointments,
      pickedDate,
      match: { params },
      id,
    } = this.props;
    const {
      photo: { show },
      popup,
      isEditingAddress,
      file: { hasFile, localFile },
      isSelectingDateTime,
      isEditingTime,
      googleApiError,
    } = this.state;
    const {
      phoneNumber,
      linkedinUrl,
      hubspotUrl,
      companyName,
      companyUrl,
      name,
      position,
      description,
      email,
      photo,
    } = { ...person };
    return (
      <Preloader showStatus={isFetching}>
        <Header className="mini">
          <Navbar />
          <RoadshowItem data={roadshow} className="roadshow-header" />
        </Header>
        {!isSelectingDateTime ? (
          <>
            <main style={{ overflowY: show ? 'hidden' : 'auto' }}>
              <Swiper
                handlerLeftSwipes={this.handleLeftSwipe}
                handlerRightSwipes={this.handleRightSwipe}
                handlerVerticalSwipes={this.handleVerticalSwipe}
              >
                <PersonInfo
                  handlePhotoClick={this.handlePhotoClick}
                  handleButton={this.handleEditData}
                  personInfo={{ name, position, companyName, companyUrl, photo }}
                />
                <MeetInfo startTime={startDateTime} meetInfo={place} status={status} />
                {media !== undefined && !!media.length && (
                  <AppointmentFiles files={media} handleFileDelete={this.handleFileDelete} />
                )}
                <AppointmentInfo description={description} />
              </Swiper>
            </main>
            <footer>
              {hasFile && (
                <CSSTransition
                  in={hasFile}
                  mountOnEnter
                  unmountOnExit
                  appear={hasFile}
                  timeout={50000}
                  classNames="slide-up"
                >
                  <div className="new-file-upload">
                    <File
                      file={localFile}
                      isUpload={hasFile}
                      handleFileUpload={this.handleFileUpload}
                      handleFileDelete={this.handleLocalDelete}
                    />
                  </div>
                </CSSTransition>
              )}
              <LinksGroup
                links={{ linkedinUrl, hubspotUrl, phoneNumber, email }}
                handleFile={this.handleFile}
                hasFile={hasFile}
              />
            </footer>
            <CSSTransition in={popup.show} timeout={200} classNames="popup">
              <Popup
                message={{ title: popup.title, text: popup.text }}
                show={popup.show}
                handleCallback={this.handlePopupState}
              />
            </CSSTransition>
            <Notification type="error" show={responseFailed} message={errorMessage} />
            <Notification type="error" show={googleApiError.length > 0} message={googleApiError} />
            {isEditingAddress && (
              <AppointmentEditAddress
                // range={{ min: roadshow.startDateTime, max: roadshow.endDateTime }}
                isFetching={isFetching}
                appointment={{ startDateTime, place, ranges }}
                handleClose={this.handleEditData}
                handleSubmit={this.handleChangeAppointmentAddress}
              />
            )}
          </>
        ) : (
          <>
            <main className="list-view edit">
              <DatePick
                startDate={roadshow.startDateTime}
                endDate={roadshow.endDateTime}
                pickedDate={this.props.pickedDate}
                handleDate={date => this.props.handlePickedDate(date)}
              />
              <Swiper
                handlerLeftSwipes={this.handleListLeftSwipe}
                handlerRightSwipes={this.handleListRightSwipe}
                className="swipe-list"
              >
                <List
                  handleEditTime={this.handleEditTime}
                  appointments={appointments}
                  freeSpots={ranges}
                  pickedDate={pickedDate}
                  appointmentId={params.id || id}
                />
              </Swiper>
            </main>
            {isEditingTime && (
              <AppointmentEditTime
                isFetching={isFetching}
                range={this.state.selectedTimeRange}
                handleClose={this.handleEditTime}
                handleSubmit={this.handleChangeAppointment}
              />
            )}
          </>
        )}
      </Preloader>
    );
  }
}

export default Appointment;
