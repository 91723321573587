import React from 'react';
import propTypes from 'prop-types';
import './index.css';

const AppointmentInfo = ({ description }) => {
  const createMarkup = () => ({ __html: description });
  return (
    <div className="appointment-info">
      <div dangerouslySetInnerHTML={createMarkup()} />
    </div>
  );
};

AppointmentInfo.propTypes = {
  description: propTypes.string,
};
AppointmentInfo.defaultProps = {
  description: '',
};
export default AppointmentInfo;
