import React from 'react';
import propTypes from 'prop-types';
import './style.css';

const Page = ({ className, children }) => {
  return <main className={`page${className && `${className}`}`}>{children}</main>;
};

Page.propTypes = {
  children: propTypes.node,
  className: propTypes.string,
};
Page.defaultProps = {
  className: '',
  children: '',
};

export default Page;
