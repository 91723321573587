import React from 'react';
import propTypes from 'prop-types';
import './icon-link.css';

const HubspotLink = ({ link, type, disabled }) => {
  const getClass = () => {
    let className = 'icon-link';
    if (disabled || link === 'false') className += ' disabled';
    switch (type) {
      case 'large':
        className += ' large';
        return className;
      case 'small':
        className += ' small';
        return className;
      default:
        return className;
    }
  };
  return (
    <>
      {link === 'false' || disabled ? (
        <div className={getClass()}>
          <i className="fab fa-hubspot" />
        </div>
      ) : (
        <a href={link} className={getClass()} rel="noopener noreferrer" target="_blank">
          <i className="fab fa-hubspot" />
        </a>
      )}
    </>
  );
};

HubspotLink.propTypes = {
  link: propTypes.string,
  type: propTypes.string,
  disabled: propTypes.bool,
};

HubspotLink.defaultProps = {
  link: '',
  type: '',
  disabled: false,
};

export default HubspotLink;
