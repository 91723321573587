import React from 'react';
import propTypes from 'prop-types';
import PreloaderComponent from '../preloader-component';
import './style.css';

const SubmitButton = ({ value, isFetching, disabled, className }) => {
  return (
    <>
      <button
        disabled={disabled}
        type="submit"
        className={`submit-input ${disabled && 'disabled'}${className && ` ${className}`}`}
      >
        {isFetching ? <PreloaderComponent showStatus={isFetching} /> : value}
      </button>
    </>
  );
};
SubmitButton.propTypes = {
  value: propTypes.string.isRequired,
  disabled: propTypes.bool.isRequired,
  isFetching: propTypes.bool,
  className: propTypes.string,
};
SubmitButton.defaultProps = {
  isFetching: false,
  className: '',
};
export default SubmitButton;
