import React from 'react';
import propTypes from 'prop-types';

export const imageRegex = /image\//;
export const videoRegex = /video\//;
export const audioRegex = /audio\//;
export const applicationRegex = /application\//;
export const pdfRegex = /application\/pdf/;

const ExtensionIcon = ({ ext, className }) => {
  switch (true) {
    case imageRegex.test(ext):
      return (
        <div className={className}>
          <i className="far fa-file-image" />
        </div>
      );
    case videoRegex.test(ext):
      return (
        <div className={className}>
          <i className="far fa-file-video" />
        </div>
      );
    case audioRegex.test(ext):
      return (
        <div className={className}>
          <i className="far fa-file-audio" />
        </div>
      );
    case pdfRegex.test(ext):
      return (
        <div className={className}>
          <i className="far fa-file-pdf" />
        </div>
      );
    case applicationRegex.test(ext):
      return (
        <div className={className}>
          <i className="far fa-file" />
        </div>
      );
    default:
      return false;
  }
};

ExtensionIcon.propTypes = { ext: propTypes.string };
ExtensionIcon.defaultProps = { ext: '' };

export default ExtensionIcon;
