import React from 'react';
import propTypes from 'prop-types';
import './style.css';

const Link = ({ href, linkText, additionalClass }) => (
  <a
    href={href}
    className={`link${additionalClass && ` ${additionalClass}`}`}
    rel="noopener noreferrer"
    target="_blank"
  >
    <h2 className="company-name">{linkText}</h2>
    <i className="fas fa-external-link-square-alt ext-link" />
  </a>
);

Link.propTypes = {
  href: propTypes.string,
  linkText: propTypes.string,
  additionalClass: propTypes.string,
};
Link.defaultProps = {
  additionalClass: '',
  href: '#',
  linkText: '',
};
export default Link;
