import { connect } from 'react-redux';
import Settings from './component';
import { clearStoreState } from '../../store/appointments/actions';

const mapDispatchToProps = dispatch => ({
  clearStoreState: () => dispatch(clearStoreState()),
});

export default connect(
  null,
  mapDispatchToProps
)(Settings);
