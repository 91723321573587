import React, { Component } from 'react';
import propTypes from 'prop-types';
import SubmitButton from '../../../../shared/components/submit-button';
import './style.css';

class Login extends Component {
  static propTypes = {
    handleSubmit: propTypes.func.isRequired,
    isFetching: propTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  render() {
    const { username, password } = this.state;
    const { isFetching, handleSubmit } = this.props;
    const validateForm = () => !(username && password);
    const inputClass = field => (field.length > 0 ? 'success' : 'error');
    return (
      <>
        <form className="login-form" onSubmit={e => handleSubmit(e, { username, password })}>
          <input
            id="username"
            className={`text-input ${inputClass(username)}`}
            type="text"
            value={username}
            onChange={this.handleInputChange}
          />
          <input
            id="password"
            className={`text-input ${inputClass(password)}`}
            type="password"
            value={password}
            onChange={this.handleInputChange}
          />
          <SubmitButton
            value="Login"
            disabled={validateForm() || isFetching}
            isFetching={isFetching}
          />
        </form>
      </>
    );
  }
}
export default Login;
