import React from 'react';
import propTypes from 'prop-types';
import { formatToDays, formatToTime } from '../../../../shared/utils/date';
import avatarPlaceholder from '../../../../assets/avatar-placeholder.png';
import './style.css';

const ListItem = ({
  handleRoute,
  appointment: { id, status, startDateTime, photo, name, companyName },
  isSelectingDateTime,
}) => {
  const getStatusClassName = () => {
    switch (status) {
      case 'confirmed':
        return 'confirm';
      case 'unconfirmed':
        return 'reject';
      case 'pending':
        return 'pending';
      default:
        return false;
    }
  };
  return (
    <div className={`list-item${getStatusClassName() !== false ? ` ${getStatusClassName()}` : ''}`}>
      <div
        className="list-item--container"
        tabIndex="0"
        role="link"
        onClick={() => {
          if (!isSelectingDateTime) {
            handleRoute(id);
          }
        }}
        onKeyPress={() => {
          if (!isSelectingDateTime) {
            handleRoute(id);
          }
        }}
      >
        <div className="list-item--image">
          <div
            style={{
              backgroundImage: `url(${
                !(photo === undefined || photo == null) ? photo : avatarPlaceholder
              })`,
            }}
          />
        </div>
        <div className="list-item--details">
          <h4 className="name">{name}</h4>
          <h4 className="company-name">{companyName}</h4>
          <h4 className="time">
            {formatToDays(startDateTime)} {formatToTime(startDateTime)}
          </h4>
        </div>
      </div>
    </div>
  );
};

ListItem.propTypes = {
  handleRoute: propTypes.func.isRequired,
  appointment: propTypes.object,
  isSelectingDateTime: propTypes.bool,
};

ListItem.defaultProps = {
  appointment: {},
  isSelectingDateTime: false,
};

export default ListItem;
