import { connect } from 'react-redux';
import LoginPage from './component';
import { login, socialLogin } from '../../../store/appointments/actions';
import { onResponseFail } from '../../../store/fetch/actions';

const mapStateToProps = ({ fetchReducer }) => ({
  isFetching: fetchReducer.isFetching,
  responseFailed: fetchReducer.responseFailed,
  errorMessage: fetchReducer.errorMessage,
});
const mapDispatchToProps = dispatch => ({
  login: authData => dispatch(login(authData)),
  socialLogin: (social, authData) => dispatch(socialLogin(social, authData)),
  onResponseFail: err => dispatch(onResponseFail(err)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
