import API from '../../api';
import * as CONSTS from './constants';
// import notificationManager from '../../shared/utils/notification';
import { onRequest, onResponseFail, onResponseSuccess } from '../fetch/actions';
import {
  modifyAppointment,
  modifyAppointments,
  modifyRoadshows,
  modifyRoadshow,
} from '../../shared/utils/appointment';

function receiveAppointment(appointment) {
  return { type: CONSTS.GET_APPOINTMENT, appointment };
}
function receiveAppointments(appointments) {
  return { type: CONSTS.GET_APPOINTMENTS, appointments };
}
function receiveRoadshow(roadshow) {
  return { type: CONSTS.GET_ROADSHOW, roadshow };
}
function receiveRoadshows(roadshows) {
  return { type: CONSTS.GET_ROADSHOWS, roadshows };
}
function receiveRanges(ranges) {
  return { type: CONSTS.GET_RANGES, ranges };
}
export function currentIdAppointment(id) {
  return { type: CONSTS.CUR_ID_APPOINTMENT, id };
}
export function currentIdRoadshow(id) {
  return { type: CONSTS.CUR_ID_ROADSHOW, id };
}
export function getPickedDate(date) {
  return { type: CONSTS.CURRENT_DATE, date };
}
export function login(authData) {
  return dispatch => {
    dispatch(onRequest());
    return API.login(authData)
      .then(() => {
        dispatch(onResponseSuccess());
      })
      .catch(err => {
        dispatch(onResponseFail(err.message));
      });
  };
}
export function socialLogin(social, token) {
  return dispatch => {
    dispatch(onRequest());
    return API.socialLogin(social, token)
      .then(() => {
        dispatch(onResponseSuccess());
      })
      .catch(err => {
        dispatch(onResponseFail(err.message));
      });
  };
}
export function deleteMedia(reqBody, id) {
  return dispatch => {
    dispatch(onRequest());
    return API.deleteMedia(reqBody, id)
      .then(() => {
        dispatch(onResponseSuccess());
      })
      .catch(err => {
        dispatch(onResponseFail(err.message));
      });
  };
}
export function sendMedia(reqBody, mediaObj) {
  return dispatch => {
    return API.sendMedia(reqBody, mediaObj)
      .then(() => {
        dispatch(onResponseSuccess());
      })
      .catch(err => {
        dispatch(onResponseFail(err.message));
      });
  };
}
export function fetchAppointment(id) {
  return dispatch => {
    dispatch(onRequest());
    dispatch(currentIdAppointment(id));
    return API.getAppointment(id)
      .then(({ body: appointment }) => {
        dispatch(onResponseSuccess());
        dispatch(receiveAppointment(modifyAppointment(appointment)));
        dispatch(receiveRoadshow(modifyRoadshow(appointment.roadshow)));
        dispatch(currentIdRoadshow(appointment.roadshow.id));
      })
      .catch(err => {
        dispatch(onResponseFail(err.message));
      });
  };
}
export function fetchRoadshows() {
  return dispatch => {
    dispatch(onRequest());
    return API.getRoadshows()
      .then(({ body: roadshows }) => {
        dispatch(onResponseSuccess());
        dispatch(receiveRoadshows(modifyRoadshows(roadshows)));
      })
      .catch(err => {
        dispatch(onResponseFail(err.message));
      });
  };
}
export function fetchRoadshowAppointments(id, date) {
  return dispatch => {
    dispatch(onRequest());
    dispatch(getPickedDate(date));
    if (id !== null)
      return API.getRoadshowAppointments(id)
        .then(({ body: appointments }) => {
          const formatedAppointments = modifyAppointments(appointments);
          dispatch(onResponseSuccess());
          dispatch(currentIdRoadshow(id));
          dispatch(receiveAppointments(formatedAppointments));
          dispatch(receiveRoadshow(modifyRoadshow(formatedAppointments[0].roadshow)));
        })
        .catch(err => {
          dispatch(onResponseFail(err.message));
        });
    return API.getAppointments()
      .then(({ body: appointments }) => {
        const formatedAppointments = modifyAppointments(appointments);
        dispatch(onResponseSuccess());
        dispatch(currentIdRoadshow(formatedAppointments[0].roadshow.id));
        dispatch(receiveRoadshow(modifyRoadshow(formatedAppointments[0].roadshow)));
        dispatch(receiveAppointments(modifyAppointments(formatedAppointments)));
        // notificationManager(formatedAppointments);
      })
      .catch(err => {
        dispatch(onResponseFail(err.message));
      });
  };
}
export function fetchActualMeetSlots(dates) {
  return dispatch => {
    dispatch(onRequest());
    return API.getActualMeetRanges(dates)
      .then(({ body: ranges }) => {
        dispatch(onResponseSuccess());
        dispatch(receiveRanges(ranges));
      })
      .catch(err => {
        dispatch(onResponseFail(err.message));
      });
  };
}
export function fetchActualMeetSlotsByLocation(location, dates) {
  return dispatch => {
    dispatch(onRequest());
    return API.getActualMeetRangesByLocation(location, dates)
      .then(({ body: ranges }) => {
        dispatch(onResponseSuccess());
        dispatch(receiveRanges(ranges));
        return Promise.resolve();
      })
      .catch(err => {
        dispatch(onResponseFail(err.message));
        return Promise.reject();
      });
  };
}
export function createAppointment(data) {
  return dispatch => {
    dispatch(onRequest());
    return API.createAppointment(data)
      .then(() => {
        dispatch(onResponseSuccess());
      })
      .catch(err => {
        dispatch(onResponseFail(err.message));
      });
  };
}
export function clearStoreState() {
  return { type: CONSTS.LOGOUT };
}
