import React from 'react';
import propTypes from 'prop-types';
import { formatToDays, formatToTime } from '../../../../shared/utils/date';
import mapPlaceholder from '../../../../assets/map-placeholder.svg';
import './style.css';

const MeetInfo = ({
  meetInfo: { address, position, info, travellingTimeTo, travellingTimeFrom, mapImage },
  startTime,
  status,
}) => {
  const getMapUrl = () => {
    const { latitude, longitude } = position;
    const mapProvider = localStorage.getItem('map-provider');
    switch (mapProvider) {
      case 'google':
        return `https://www.google.com/maps/search/?api=1&query=${address}`;
      case 'apple':
        return `http://maps.apple.com/?daddr=${`${latitude},${longitude}`}`;
      case 'citymapper':
        return `https://citymapper.com/directions?&endcoord=${`${latitude},${longitude}`}&endaddress=${address}`;
      default:
        return `https://www.google.com/maps/search/?api=1&query=${address}`;
    }
  };
  const getStatusIcon = () => {
    switch (status) {
      case 'confirmed':
        return <i className="fas fa-check-circle confirm" />;
      case 'unconfirmed':
        return <i className="fas fa-times-circle reject" />;
      case 'pending':
        return <i className="fas fa-exclamation-circle pending" />;
      default:
        return false;
    }
  };
  return (
    <a className="meet-info" href={getMapUrl()} target="_blank" rel="noopener noreferrer">
      <div className="meet-info--text-placeholder">
        <div className="location-info">
          <div className="icon">
            <i className="fas fa-map-marker-alt" />
          </div>
          <div className="info">
            <p className="text-info">
              {address} <br />
              {info}
            </p>
          </div>
          <div className="status-icon">{getStatusIcon()}</div>
        </div>
        <div className="time-info">
          <span className="travel-time">{travellingTimeTo}</span>
          <div className="meet-time">
            <h4>{formatToTime(startTime)}</h4>
            <h5>{formatToDays(startTime)}</h5>
            <div className="container">
              <div className="line" />
              <div className="dot" />
            </div>
          </div>
          <span className="travel-time">{travellingTimeFrom}</span>
        </div>
      </div>
      <div
        className="meet-info--image-placeholder"
        style={{
          backgroundImage: `url(${
            !(mapImage === undefined || mapImage == null) ? mapImage : mapPlaceholder
          })`,
        }}
      />
    </a>
  );
};

MeetInfo.propTypes = {
  meetInfo: propTypes.shape({
    travellingTimeFrom: propTypes.string,
    travellingTimeTo: propTypes.string,
    mapImage: propTypes.string,
    position: propTypes.shape({
      latitude: propTypes.number,
      longitude: propTypes.number,
    }),
    info: propTypes.string,
    address: propTypes.string,
  }),
  startTime: propTypes.string,
  status: propTypes.string,
};

MeetInfo.defaultProps = {
  meetInfo: {
    position: {
      latitude: 0,
      longitude: 0,
    },
    info: '',
    address: '',
    travellingTimeTo: '',
    travellingTimeFrom: '',
    mapImage: '',
  },
  startTime: '',
  status: '',
};

export default MeetInfo;
