import React from 'react';
import propTypes from 'prop-types';
import File from '../../../../shared/components/user-file';
import './style.css';

const AppointmentFiles = ({ files, handleFileDelete }) => {
  return (
    <section className="appointment-media">
      <h3 className="title">Meet attachments</h3>
      <div className="attachments">
        {files.map(file => (
          <File
            className="file-item"
            file={file}
            key={file.id}
            handleFileDelete={handleFileDelete}
          />
        ))}
      </div>
    </section>
  );
};
AppointmentFiles.propTypes = {
  files: propTypes.array.isRequired,
  handleFileDelete: propTypes.func,
};
AppointmentFiles.defaultProps = {
  handleFileDelete: () => {},
};
export default AppointmentFiles;
